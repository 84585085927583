/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GenCmsBanner, GenCmsBannerId, GenCmsComponentId } from './data-contracts';
import { createRequest } from './http-client';

export const CmsBannersApi = {
  /**
   * No description
   * * @tags CMS-BANNERS
   * @name ListBanners
   * @summary Получение баннеров
   * @request GET:/cms/banners
   * @secure
   */
  listBanners: (
    baseURL: string,
    query: {
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** список id баннеров */
      bannerIds?: GenCmsBannerId[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenCmsBanner[], void>({
      baseURL,
      path: `/cms/banners`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags CMS-BANNERS
   * @name GetBannerById
   * @summary Получение баннера
   * @request GET:/cms/banners/{bannerId}
   * @secure
   */
  getBannerById: (baseURL: string, bannerId: GenCmsComponentId, signal?: AbortSignal) =>
    createRequest<GenCmsBanner, void>({
      baseURL,
      path: `/cms/banners/${bannerId}`,
      method: 'GET',
      signal,
    }),
};
