import { CustomersApi } from '@privilege-frontend/api';
import { getQueryErrorByAxiosError } from 'api/utils';
import { Cart, CartItem, ProductOfferShort } from 'domain/model';
import { getBaseEndpoint } from 'openApi/utils';
import { api, ECartServicesTag } from './index';

type GetCartItemRequest = {
  readonly id: UUID;
};

type DeleteCartItemRequest = {
  readonly id: UUID;
};

export enum EUpdateBehaviour {
  Optimistic = 'optimistic',
  Pessimistic = 'pessimistic',
}

type UpdateCartItemRequest = {
  readonly cartItemId: UUID;
  readonly productId: UUID;
  readonly quantity: number;
  readonly behaviour?: EUpdateBehaviour;
};

type AddItemToCartRequest = {
  readonly product: ProductOfferShort;
  readonly quantity: number;
};

export const cartApi = api.injectEndpoints({
  endpoints: builder => ({
    getCart: builder.query<Cart, void>({
      queryFn: async (_, { signal }) => {
        try {
          const { data } = await CustomersApi.getBasket(getBaseEndpoint(), signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [ECartServicesTag.Cart],
    }),
    getCartItem: builder.query<CartItem, GetCartItemRequest>({
      queryFn: async ({ id }, { signal }) => {
        try {
          const { data } = await CustomersApi.getBasketItemById(getBaseEndpoint(), id, signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    deleteCartItem: builder.mutation<number, DeleteCartItemRequest>({
      queryFn: async ({ id }, { signal }) => {
        try {
          const { status: data } = await CustomersApi.deleteBasketItem(getBaseEndpoint(), id, signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const cartPatch = dispatch(
          cartApi.util.updateQueryData('getCart', undefined, draft => {
            const withoutItemList = draft?.items?.filter(i => i.id !== id);
            if (withoutItemList) {
              Object.assign(draft, {
                ...draft,
                items: [...withoutItemList],
              });
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          cartPatch.undo();
        }
      },
    }),
    updateCartItem: builder.mutation<CartItem, UpdateCartItemRequest>({
      queryFn: async ({ productId, cartItemId, quantity }, { signal }) => {
        try {
          const { data } = await CustomersApi.updateBasketItem(
            getBaseEndpoint(),
            cartItemId,
            {
              offer: productId,
              quantity,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      async onQueryStarted(
        { productId, quantity, cartItemId, behaviour = EUpdateBehaviour.Optimistic },
        { dispatch, queryFulfilled }
      ) {
        if (behaviour === EUpdateBehaviour.Optimistic) {
          const cartPatch = dispatch(
            cartApi.util.updateQueryData('getCart', undefined, draft => {
              const updatingCartItem = draft?.items?.find(i => i.id === cartItemId);
              if (updatingCartItem) {
                Object.assign(updatingCartItem, {
                  ...updatingCartItem,
                  quantity,
                  offer: { ...updatingCartItem.offer, id: productId },
                });
              }
            })
          );

          try {
            await queryFulfilled;
          } catch {
            cartPatch.undo();
          }
        } else {
          try {
            const { data } = await queryFulfilled;
            dispatch(
              cartApi.util.updateQueryData('getCart', undefined, draft => {
                const updatingCartItem = draft?.items?.find(i => i.id === cartItemId);
                if (updatingCartItem) {
                  Object.assign(updatingCartItem, data);
                }
              })
            );
          } catch (e) {
            console.error(e);
          }
        }
      },
    }),
    addItemToCart: builder.mutation<CartItem, AddItemToCartRequest>({
      queryFn: async ({ product, quantity }, { signal }) => {
        try {
          const { data } = await CustomersApi.addOfferToBasket(
            getBaseEndpoint(),
            {
              offer: product.id,
              quantity,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      async onQueryStarted({ product, quantity }, { dispatch, queryFulfilled }) {
        const cartPatch = dispatch(
          cartApi.util.updateQueryData('getCart', undefined, draft => {
            // данный айтем-заглушка перезапишется полученным с сервера
            const newCartItem: CartItem = {
              id: '',
              quantity,
              offer: product,
            };

            if (draft?.items) {
              Object.assign(draft, { ...draft, items: [...draft.items, newCartItem] });
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          cartPatch.undo();
        }
      },
    }),
  }),
});

export const {
  useGetCartQuery,
  useDeleteCartItemMutation,
  useGetCartItemQuery,
  useUpdateCartItemMutation,
  useAddItemToCartMutation,
} = cartApi;
