import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FormattedAttributes } from '../../utils';
import SidebarAttribute from '../attribute';

type SidebarAttributesProps = {
  readonly attributes: FormattedAttributes[];
};

const SidebarAttributes = ({ attributes }: SidebarAttributesProps) => {
  return (
    <MPGrid
      container
      spacing={1}
      flexDirection='column'
    >
      {attributes.map(a => (
        <MPGrid key={a.value}>
          <SidebarAttribute
            name={a.label}
            value={a.value}
          />
        </MPGrid>
      ))}
    </MPGrid>
  );
};

export default SidebarAttributes;
