import { EOfferType } from 'domain/model';
import CorpOfferListContainer from 'features/offer/corp/list/container';
import TradeOfferListContainer from 'features/offer/trade/list/container';
import React from 'react';
import { CmsOfferCategoriesCollectionProps } from './types';

/**
 * фича отображения состава офферов по коллекции категорий
 */
export const CmsOfferCategoriesCollection: React.FCC<CmsOfferCategoriesCollectionProps> = props => {
  const { collection, guid, partnerId, offerType, header } = props;

  const categoryIds = collection.linkObjectIds;

  switch (offerType) {
    case EOfferType.CorpOffer:
      return (
        <CorpOfferListContainer
          guid={guid}
          name={null}
          categoryIds={categoryIds}
          partnerId={partnerId ?? null}
          header={header}
        />
      );
    case EOfferType.TradeOffer:
      return (
        <TradeOfferListContainer
          guid={guid}
          name={null}
          categoryIds={categoryIds}
          partnerId={partnerId ?? null}
          header={header}
        />
      );
    case EOfferType.BookingOffer:
    case EOfferType.ProductOffer:
      break;
  }
};
