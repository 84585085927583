import { Typography } from '@mui/material';
import { PartnerDesk } from 'domain/model';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import PartnerDeskLogo from '../logo';
import { LogoWrapper } from './controls';

type PartnerDeskHeaderProps = {
  readonly partnerDesk: PartnerDesk;
};

const PartnerDeskHeader = ({ partnerDesk }: PartnerDeskHeaderProps) => {
  const { x2 } = useGridSpacing();

  return (
    <MPGrid
      container
      spacing={x2}
      alignItems='center'
    >
      <LogoWrapper>
        <PartnerDeskLogo partnerDesk={partnerDesk} />
      </LogoWrapper>
      <MPGrid size='grow'>
        <Typography variant='h1'>{partnerDesk.partner?.name}</Typography>
      </MPGrid>
    </MPGrid>
  );
};

export default PartnerDeskHeader;
