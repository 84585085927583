import CorpLinkProxy from 'components/common/links/proxy';
import useCorpOfferClaimContext from 'features/offer/corp/claim/provider/useCorpOfferClaimContext';
import { AppConfigurator } from 'system/appConfigurator';
import { EarnPointsButton } from './controls';

export const CorpOfferClaimEarnPointsAdapter = () => {
  const corpEarningUrl = AppConfigurator.getInstance().getCorpEarningsSystemUrl();
  const { activation } = useCorpOfferClaimContext();
  const { isUserBalanceNotEnough } = activation;

  return corpEarningUrl && isUserBalanceNotEnough ? (
    <CorpLinkProxy link={corpEarningUrl}>
      {linkByProxy => (
        <EarnPointsButton
          type='button'
          color='brand'
          size='large'
          fullWidth
          onClick={() => window.open(linkByProxy)}
        >
          Заработать баллы
        </EarnPointsButton>
      )}
    </CorpLinkProxy>
  ) : null;
};
