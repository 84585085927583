import { BookingOrderOffer, EDateFormat } from 'domain/model';
import { BookingDateSlot } from 'domain/model/order';
import { getBookingSlotLabel } from 'features/offer/booking/components/modal/constants';
import moment from 'moment-timezone';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FC } from 'react';
import { BookingSlotItem } from './slotItem';

type BookingOrderItemSlotMultipleProps = {
  readonly bookingOffer: BookingOrderOffer;
  readonly slots: BookingDateSlot[];
};

const BookingOrderItemSlotMultiple: FC<BookingOrderItemSlotMultipleProps> = ({ bookingOffer, slots }) => {
  return (
    <>
      <MPGrid
        container
        spacing={1}
      >
        <BookingSlotItem
          title={getBookingSlotLabel(bookingOffer)}
          width={2}
        >
          {slots.map(({ qty, startDate }) => (
            <div key={`${startDate}-${qty}`}>{qty}</div>
          ))}
        </BookingSlotItem>
        <BookingSlotItem
          title='Дата'
          width={10}
        >
          {slots.map(({ startDate }) => (
            <div key={startDate as string}>{moment(startDate).format(EDateFormat.Human)}</div>
          ))}
        </BookingSlotItem>
      </MPGrid>
    </>
  );
};

export default BookingOrderItemSlotMultiple;
