/** факт доступности элемента корзины для оформления заказа */
import { CartItem, EOfferStatus } from 'domain/model';

export const isCartItemEnabled = (item: CartItem): boolean => {
  const count = item.offer?.stock ?? 0;
  return item.offer?.status === EOfferStatus.Active && count > 0;
};

export const isCartItemStockOutOfQuantity = (item: CartItem): boolean => {
  const count = item.offer?.stock ?? 0;
  return (item.quantity ?? 0) > count;
};
