import { Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { CartIcon } from 'presentation/media/icons';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { Icon } from './controls';

type CartEmptyProps = {
  readonly onOpenProducts: () => void;
};

const CartEmpty = ({ onOpenProducts }: CartEmptyProps) => {
  return (
    <MPGrid
      container
      spacing={2}
      direction='column'
      alignItems='center'
      justifyContent='center'
    >
      <MPGrid>
        <Icon icon={CartIcon} />
      </MPGrid>
      <MPGrid>
        <Typography variant='subtitle1'>Корзина пуста</Typography>
      </MPGrid>
      <MPGrid>
        <Typography align='center'>Добавьте в корзину товары для оформления заказов</Typography>
      </MPGrid>
      <MPGrid>
        <Splitter size={2} />
        <MPButton onClick={onOpenProducts}>Перейти к товарам</MPButton>
      </MPGrid>
    </MPGrid>
  );
};

export default CartEmpty;
