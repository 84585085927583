import { FC } from 'react';
import { Typography } from '@mui/material';
import { BookingSlotPeriod, EOrderByDateType } from 'domain/model';
import { toRubCurrency } from 'presentation/utils/currency';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { EditIcon, TrashIcon } from 'presentation/media/icons';
import { MPFab } from 'presentation/theme/ui-kit/button';
import { calcPrice } from './utils';
import { SlotProps } from './types';

type BookingSidebarSlotActionsProps = SlotProps & {
  unitType: EOrderByDateType;
  price: number;
  slots: BookingSlotPeriod[];
  slotIndex?: number;
};

const BookingSidebarSlotActions: FC<BookingSidebarSlotActionsProps> = ({
  unitType,
  price,
  slots,
  index,
  slotIndex,
  onChange,
  onDelete,
}) => {
  const clickValue = typeof slotIndex === 'number' ? [index, slotIndex] : [index];

  return (
    <MPGrid
      container
      spacing={1}
      justifyContent='space-between'
      alignItems='center'
    >
      <MPGrid
        display='flex'
        gap={1}
      >
        <MPFab
          color='secondarySuperLight'
          size='small'
          variant='squared'
          onClick={() => onChange(clickValue)}
        >
          <EditIcon
            fontSize='small'
            color='primary'
          />
        </MPFab>
        <MPFab
          color='secondarySuperLight'
          size='small'
          variant='squared'
          onClick={() => onDelete(clickValue)}
        >
          <TrashIcon
            fontSize='small'
            color='primary'
          />
        </MPFab>
      </MPGrid>
      <MPGrid>
        <Typography variant='subtitle1'>{toRubCurrency(calcPrice(unitType, price, slots))}</Typography>
      </MPGrid>
    </MPGrid>
  );
};

export default BookingSidebarSlotActions;
