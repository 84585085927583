import { useMediaQuery } from '@mui/material';
import { useContextSearchParams } from 'features/offer/booking/list/hooks/useContextSearchParams';
import DataFilterButton from 'presentation/components/common/dataFilter/button';
import { BookingOffersSearchListLocationState } from 'presentation/features/offer/booking/types';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { bookingOfferListNoDataSelector } from '../store/selectors';
import { BookingOfferListFilterCount } from '../types';
import { BookingOfferListMobileFilterAdapter } from './filterMobile';
import { BookingOfferListSortSelectAdapter } from './sortSelect';

type BookingOfferListControlAdapterProps = BookingOffersSearchListLocationState & BookingOfferListFilterCount;

export const BookingOfferListControlAdapter: FC<BookingOfferListControlAdapterProps> = props => {
  const { categoryId, filterCount } = props;

  const searchParams = useContextSearchParams();

  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const noData = useSelector(state => bookingOfferListNoDataSelector(state, searchParams));

  const [isShowFilter, setShowFilter] = useState(false);

  return (
    <MPGrid
      container
      spacing={1}
      justifyContent='space-between'
      alignItems='center'
    >
      <MPGrid>
        <BookingOfferListSortSelectAdapter {...props} />
      </MPGrid>
      {!isMdUp && categoryId && (!noData || !!filterCount) && (
        <MPGrid>
          <DataFilterButton
            count={filterCount}
            onClick={() => setShowFilter(true)}
          />
        </MPGrid>
      )}
      {!isMdUp && isShowFilter && (
        <BookingOfferListMobileFilterAdapter
          filterCount={filterCount}
          onBack={() => setShowFilter(false)}
        />
      )}
    </MPGrid>
  );
};
