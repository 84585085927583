import { AnalyticsAdapter, AnalyticsContainer, AnalyticsType, VisitHistory } from '@mp-npm/mp-analytics-client';
import { UserEventsApi } from '@privilege-frontend/api';
import { OfferUserEvent } from 'domain/model';
import { getBaseEndpoint } from 'openApi/utils';
import { AppConfigurator } from '../../../system/appConfigurator';
import { EAppFeature } from '../../types';
import { WebAnalyticsConfigurator } from './configurator/webAnalyticsConfigurator';
import { WebAnalytics } from './types';

const getYandexAdapter = (history: VisitHistory): AnalyticsAdapter => {
  const configurator = WebAnalyticsConfigurator.getInstance();
  const config = configurator.getYandexConfig();
  const enabled = AppConfigurator.getInstance().hasFeature(EAppFeature.WebAnalytics) && config.enabled;
  return {
    type: AnalyticsType.Yandex,
    id: config.id,
    debug: configurator.isDebugMode(),
    enabled: enabled && !!config.id,
    enabledHit: true,
    visitParams: {},
    goals: Object.values(config.goals).map(v => v),
    options: {
      clickmap: config.clickmap,
      webvisor: config.webvisor,
    },
    history,
  };
};

const getSelfHostedAdapter = (): AnalyticsAdapter => {
  const configurator = WebAnalyticsConfigurator.getInstance();
  const config = configurator.getSelfHostedConfig();
  const enabled = AppConfigurator.getInstance().hasFeature(EAppFeature.WebAnalytics) && config.enabled;
  return {
    type: AnalyticsType.SelfHosted,
    debug: configurator.isDebugMode(),
    enabled,
    goals: Object.values(config.goals).map(v => v),
    buffer: {
      lifeTime: config.buffer.timeout,
      goals: config.buffer.goals,
      onEntityGoal: entities => {
        if (entities.length) {
          const data: OfferUserEvent[] = entities.map(item => ({ offer: { id: item.entityId }, typeCode: item.goal }));
          return UserEventsApi.postOfferUserEvents(getBaseEndpoint(), data).catch(error =>
            console.error('Self analytics error', error)
          );
        }
        return Promise.resolve();
      },
    },
    onEntityGoal: ({ id, params }) => {
      if (id && params?.entityId) {
        const data: OfferUserEvent[] = [{ offer: { id: params.entityId }, typeCode: id }];
        return UserEventsApi.postOfferUserEvents(getBaseEndpoint(), data).catch(error =>
          console.error('Self analytics error', error)
        );
      }
      return Promise.resolve();
    },
  };
};

export const webAnalytics: WebAnalytics = {
  getAdapters: history => {
    return [getYandexAdapter(history), getSelfHostedAdapter()];
  },

  //view
  offerViewInSearch: offerId => {
    const id = WebAnalyticsConfigurator.getInstance().getGoals().offerViewInSearchId;
    if (id) {
      AnalyticsContainer.getInstance().entityGoal({ id, params: { entityId: offerId } });
    }
  },
  offerViewInList: offerId => {
    const id = WebAnalyticsConfigurator.getInstance().getGoals().offerViewInListId;
    if (id) {
      AnalyticsContainer.getInstance().entityGoal({ id, params: { entityId: offerId } });
    }
  },
  offerView: offerId => {
    const id = WebAnalyticsConfigurator.getInstance().getGoals().offerViewId;
    if (id) {
      AnalyticsContainer.getInstance().entityGoal({ id, params: { entityId: offerId } });
    }
  },

  //show
  offerShowInSearch: offerId => {
    const id = WebAnalyticsConfigurator.getInstance().getGoals().offerShowInSearchId;
    if (id) {
      AnalyticsContainer.getInstance().entityGoal({ id, params: { entityId: offerId } });
    }
  },
  offerShowInList: offerId => {
    const id = WebAnalyticsConfigurator.getInstance().getGoals().offerShowInListId;
    if (id) {
      AnalyticsContainer.getInstance().entityGoal({ id, params: { entityId: offerId } });
    }
  },
  offerShow: offerId => {
    const id = WebAnalyticsConfigurator.getInstance().getGoals().offerShowId;
    if (id) {
      AnalyticsContainer.getInstance().entityGoal({ id, params: { entityId: offerId } });
    }
  },

  //activation
  offerActivate: offerId => {
    const id = WebAnalyticsConfigurator.getInstance().getGoals().offerReceiveClickedId;
    if (id) {
      AnalyticsContainer.getInstance().entityGoal({ id, params: { entityId: offerId } });
    }
  },
  offerTakeActivation: offerId => {
    const id = WebAnalyticsConfigurator.getInstance().getGoals().offerTakeActivationId;
    if (id) {
      AnalyticsContainer.getInstance().entityGoal({ id, params: { entityId: offerId } });
    }
  },

  //favorite
  offerAddToFavorite: offerId => {
    const id = WebAnalyticsConfigurator.getInstance().getGoals().offerAddToFavorites;
    if (id) {
      AnalyticsContainer.getInstance().entityGoal({ id, params: { entityId: offerId } });
    }
  },

  //others
  offerJumpToPartnerSite: offerId => {
    const id = WebAnalyticsConfigurator.getInstance().getGoals().jumpToPartnerSiteId;
    if (id) {
      AnalyticsContainer.getInstance().entityGoal({ id, params: { entityId: offerId } });
    }
  },

  //legacy
  openPartnerUrl: () => {
    const id = WebAnalyticsConfigurator.getInstance().getGoals().partnerOpenUrlId;
    if (id) {
      AnalyticsContainer.getInstance().goal({ id });
    }
  },
  tradeOfferActivate: () => {
    const id = WebAnalyticsConfigurator.getInstance().getGoals().tradeOfferActivateId;
    if (id) {
      AnalyticsContainer.getInstance().goal({ id });
    }
  },
  tradeOfferReactivate: () => {
    const id = WebAnalyticsConfigurator.getInstance().getGoals().tradeOfferReactivateId;
    if (id) {
      AnalyticsContainer.getInstance().goal({ id });
    }
  },
  tradeOfferActivateByCopy: () => {
    const id = WebAnalyticsConfigurator.getInstance().getGoals().tradeOfferActivateByCopyId;
    if (id) {
      AnalyticsContainer.getInstance().goal({ id });
    }
  },
};
