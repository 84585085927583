import { Button } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { StyledToggleButton } from './controls';

type MPButtonsGroupItem = {
  readonly id: string | number;
  readonly name: string;
};

type MPButtonsGroupProps<T extends MPButtonsGroupItem> = {
  readonly items: T[];
  readonly selected: T[];
  readonly size?: 'small' | 'medium' | 'large';
  readonly color?: 'standard' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  readonly exclusive?: boolean;
  readonly disabled?: boolean;
  readonly onChange: (values: T[]) => void;
};

export function MPButtonsGroup<T extends MPButtonsGroupItem>(props: MPButtonsGroupProps<T>) {
  const { items, selected, color = 'standard', size = 'small', exclusive = false, disabled = false, onChange } = props;

  const getSelectedItemIndex = (item: T) => selected.findIndex(s => s.id === item.id);

  const onChangeSelectedItem = (item: T) => {
    const selectedIndex = getSelectedItemIndex(item);

    if (selectedIndex !== -1 && !exclusive) {
      const result = [...selected];
      result.splice(selectedIndex, 1);
      onChange(result);
      return;
    }

    if (!exclusive) {
      onChange([...selected, item]);
    } else {
      onChange([item]);
    }
  };

  return (
    <MPGrid
      container
      spacing={1}
    >
      {items.map(item => (
        <MPGrid key={item.id}>
          <StyledToggleButton
            component={Button}
            variant='contained'
            size={size}
            color={color}
            value={item.id}
            disabled={disabled}
            selected={getSelectedItemIndex(item) !== -1}
            onClick={() => onChangeSelectedItem(item)}
          >
            {item.name}
          </StyledToggleButton>
        </MPGrid>
      ))}
    </MPGrid>
  );
}
