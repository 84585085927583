import React, { FC } from 'react';
import { Typography } from '@mui/material';
import useTechConfig from 'hooks/useTechConfig';
import { MPGrid } from 'theme/ui-kit/grid';
import { MPFormInput, MPFormTextArea } from 'theme/ui-kit/input';
import { EAppFeature, OnChangeObjectAttribute } from 'presentation/types';
import AboutPersonalDataAgreementPublicLink from 'features/about/components/personalDataAgreementLink/public';
import { UserFeedBackForm } from 'domain/model';
import { ValidationResult } from 'presentationUtils/validation';

type FormAdapterProps = {
  readonly data: UserFeedBackForm;
  readonly isFetching: boolean;
  readonly validation: Nullable<ValidationResult<UserFeedBackForm>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<UserFeedBackForm>;
};

export const FormAdapter: FC<FormAdapterProps> = ({ data, isFetching, validation, onChangeAttribute }) => {
  const { hasFeature } = useTechConfig();

  return (
    <MPGrid
      container
      spacing={2}
      direction='column'
    >
      <MPGrid>
        <Typography variant='body2'>Напишите нам, если у вас есть чем поделиться. Это сделает нас лучше</Typography>
      </MPGrid>
      <MPGrid>
        <MPFormInput
          label='Фамилия и имя'
          value={data.name ?? ''}
          error={!!validation?.name?.hasError}
          helperText={validation?.name?.message}
          disabled={isFetching}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeAttribute('name', event.target.value)}
        />
      </MPGrid>
      <MPGrid>
        <MPFormInput
          label='Электронная почта'
          value={data.email ?? ''}
          error={!!validation?.email?.hasError}
          helperText={validation?.email?.message}
          disabled={isFetching}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeAttribute('email', event.target.value)}
        />
      </MPGrid>
      <MPGrid>
        <MPFormTextArea
          multiline
          slotProps={{ htmlInput: { maxLength: 1000 } }}
          rows={5}
          label='Сообщение'
          value={data.message ?? ''}
          error={!!validation?.message?.hasError}
          helperText={validation?.message?.message}
          disabled={isFetching}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeAttribute('message', event.target.value)}
        />
      </MPGrid>
      {hasFeature(EAppFeature.PersonalDataAgreement) && (
        <MPGrid>
          <Typography variant='caption'>
            Нажимая кнопку «Отправить», я даю свое согласие на обработку моих персональных данных, в соответствии с
            Федеральным законом от 27.07.2006 г. №152-ФЗ «О персональных данных», на условиях и для целей, определенных
            в{' '}
            <AboutPersonalDataAgreementPublicLink
              color='primary'
              underline='hover'
            >
              Согласии на обработку персональных данных
            </AboutPersonalDataAgreementPublicLink>
          </Typography>
        </MPGrid>
      )}
    </MPGrid>
  );
};
