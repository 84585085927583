import { Typography, useMediaQuery } from '@mui/material';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPDialog } from 'presentation/theme/ui-kit/dialog';
import { MPGrid } from 'presentation/theme/ui-kit/grid';

type OfferUnavailableDialogProps = {
  readonly open: boolean;
  readonly onShowOtherOffers: () => void;
  readonly onClose: () => void;
};

// todo вызывается при клике на показать поностью из инфо мессадж
const OfferUnavailableDialog = ({ open, onShowOtherOffers, onClose }: OfferUnavailableDialogProps) => {
  const { x3 } = useGridSpacing();

  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <MPDialog
      open={open}
      maxWidth='xs'
      title='Предложение недоступно'
      onClose={onClose}
    >
      <MPGrid container>
        <MPGrid
          size={12}
          container
          direction='column'
          spacing={x3}
        >
          <MPGrid>
            <Typography>К сожалению, данное предложение недоступно.</Typography>
            <Typography>Перейдите по ссылке, чтобы ознакомиться с актуальными для вас предложениями.</Typography>
          </MPGrid>
          {isSmDown && (
            <MPGrid alignSelf='center'>
              <MPButton onClick={onShowOtherOffers}>Посмотреть другие предложения</MPButton>
            </MPGrid>
          )}
          {!isSmDown && (
            <MPGrid>
              <MPButton onClick={onShowOtherOffers}>Посмотреть другие предложения</MPButton>
            </MPGrid>
          )}
        </MPGrid>
      </MPGrid>
    </MPDialog>
  );
};

export default OfferUnavailableDialog;
