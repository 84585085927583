import { BookingOffersRequest, useGetBookingOfferListQuery } from 'data/api/bookingOffer';
import ErrorHandler from 'data/network/errorHandler';
import { BookingOfferShort } from 'domain/model';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { ERenderSource } from 'presentation/types';
import { FC, useCallback, useEffect } from 'react';
import InView from '../../../../components/common/inView';
import OfferListItemContainer from '../../listItem/container';
import { BookingOffersSearchListLocationState } from '../types';
import useCategoriesData from './hooks/useCategoriesData';
import { useContextHandlers } from './hooks/useContextHandlers';

type BookingOfferListDataProps = Pick<BookingOffersSearchListLocationState, 'categoryId'> & {
  readonly guid: UUID;
  readonly args: BookingOffersRequest;
  readonly onShowCard: (tradeOffer: BookingOfferShort) => void;
};

const BookingOfferListData: FC<BookingOfferListDataProps> = ({ args, guid, categoryId, onShowCard }) => {
  const { page } = args;

  const { onChangePage } = useContextHandlers();

  const { isAuthenticated } = useAuth();
  const { subCategories } = useCategoriesData({ categoryId: categoryId ?? null });

  const { data, error, isSuccess, isFetching } = useGetBookingOfferListQuery(args, {
    refetchOnMountOrArgChange: true,
  });

  const onLoadMore = useCallback(() => {
    onChangePage(page + 1);
  }, [page, onChangePage]);

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  const offers = data?.data;
  const totalCount = data?.totalCount ?? 0;
  const hasOffers = !!offers && offers.length > 0;

  const hasSubCategories = !!subCategories?.length;

  if (!hasOffers) {
    return null;
  }

  const sessionKey = JSON.stringify(args);

  return (
    <CardPageableList
      span={categoryId || hasSubCategories ? 4 : 3}
      lgSpan={categoryId || hasSubCategories ? 6 : 4}
      mdSpan={categoryId || hasSubCategories ? 6 : 4}
      totalCount={totalCount}
      onLoadMore={onLoadMore}
    >
      {offers.map(offer => (
        <InView
          key={offer.id}
          active={isSuccess && !isFetching}
          sessionKey={sessionKey}
          data={offer}
          onShow={onShowCard}
        >
          <OfferListItemContainer
            key={offer.id}
            offer={offer}
            favorite={
              isAuthenticated && {
                guid,
              }
            }
            renderFrom={ERenderSource.OfferList}
          />
        </InView>
      ))}
    </CardPageableList>
  );
};

export default BookingOfferListData;
