import { CmsBannersApi, CmsCollectionApi, CmsContainersApi, CollectionsApi } from '@privilege-frontend/api';
import { getQueryErrorByAxiosError } from 'api/utils';
import {
  Category,
  CmsBanner,
  CmsCollection,
  CorpOfferShort,
  EOfferStatus,
  LandingWindow,
  PartnerDesk,
  PartnerWindow,
  TradeOfferShort,
} from 'domain/model';
import { getBaseEndpoint } from 'openApi/utils';
import { api, EUserServicesTag } from './index';
import { sortArrayByIds } from 'presentation/utils';

type CmsApiParams<T> = T & {
  readonly guid: UUID;
};

export type CmsApiOffersRequest = CmsApiParams<{
  readonly id: UUID;
  readonly city: Nullable<string>;
  readonly statuses?: Nullable<EOfferStatus[]>;
}>;

export type CmsApiOfferCategoriesRequest = CmsApiParams<{
  readonly id: UUID;
  readonly city: Nullable<string>;
}>;

export const cmsApi = api.injectEndpoints({
  endpoints: builder => ({
    getCmsPartnerPage: builder.query<PartnerWindow, CmsApiParams<{ id: UUID }>>({
      keepUnusedDataFor: 3600,
      queryFn: async ({ id }, { signal }) => {
        try {
          const { data } = await CmsContainersApi.listCustomerContainers(
            getBaseEndpoint(),
            { page: 0, size: 100000, partnerId: id },
            signal
          );
          return {
            data: data as PartnerWindow,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.Location],
    }),
    getCmsLandingPage: builder.query<LandingWindow, UUID>({
      keepUnusedDataFor: 3600,
      queryFn: async (_, { signal }) => {
        try {
          const { data } = await CmsContainersApi.listCustomerContainers(
            getBaseEndpoint(),
            { page: 0, size: 100000 },
            signal
          );
          return {
            data: data as LandingWindow,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.Location],
    }),
    getCmsContainerBannersContent: builder.query<CmsBanner[], CmsApiParams<{ bannerIds: UUID[], stableSort?: boolean }>>({
      keepUnusedDataFor: 3600,
      queryFn: async ({ bannerIds, stableSort = false }, { signal }) => {
        try {
          const { data } = await CmsBannersApi.listBanners(
            getBaseEndpoint(),
            { page: 0, size: 100000, bannerIds },
            signal
          );

          if (stableSort) {
            return {
              data: sortArrayByIds(data as CmsBanner[], bannerIds)
            }
          }

          return {
            data: data as CmsBanner[],
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.Location],
    }),
    getCmsCollection: builder.query<CmsCollection, CmsApiParams<{ id: UUID }>>({
      keepUnusedDataFor: 3600,
      queryFn: async ({ id }, { signal }) => {
        try {
          const { data } = await CmsCollectionApi.getCollectionById(getBaseEndpoint(), id, signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.Location],
    }),
    getCmsTradeOffersCollection: builder.query<TradeOfferShort[], CmsApiOffersRequest>({
      keepUnusedDataFor: 3600,
      queryFn: async ({ id, statuses }, { signal }) => {
        try {
          const response = await CollectionsApi.getCustomerTradeOfferCollection(getBaseEndpoint(), id, signal);
          const data = response.data.filter(
            item => !statuses?.length || (item.status && statuses.includes(item.status))
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.Location],
    }),
    getCmsCorpOffersCollection: builder.query<CorpOfferShort[], CmsApiOffersRequest>({
      keepUnusedDataFor: 3600,
      queryFn: async ({ id, statuses }, { signal }) => {
        try {
          const response = await CollectionsApi.getCustomerCorpOfferCollection(getBaseEndpoint(), id, signal);
          const data = response.data.filter(
            item => !statuses?.length || (item.status && statuses.includes(item.status))
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.Location],
    }),
    getCmsPartnerDesksCollection: builder.query<PartnerDesk[], CmsApiParams<{ id: UUID }>>({
      keepUnusedDataFor: 3600,
      queryFn: async ({ id }, { signal }) => {
        try {
          const { data } = await CollectionsApi.getCustomerPartnerDeskCollection(getBaseEndpoint(), id, signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.Location],
    }),
    getCmsOfferCategoriesCollection: builder.query<Category[], CmsApiOfferCategoriesRequest>({
      keepUnusedDataFor: 3600,
      queryFn: async ({ id }, { signal }) => {
        try {
          const { data } = await CollectionsApi.getCustomerOfferCategoriesCollection(getBaseEndpoint(), id, signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EUserServicesTag.Location],
    }),
  }),
});

export const {
  useGetCmsPartnerPageQuery,
  useGetCmsLandingPageQuery,
  useGetCmsContainerBannersContentQuery,
  useGetCmsCollectionQuery,
  useGetCmsTradeOffersCollectionQuery,
  useGetCmsCorpOffersCollectionQuery,
  useGetCmsPartnerDesksCollectionQuery,
  useGetCmsOfferCategoriesCollectionQuery,
} = cmsApi;
