export enum ECorpOfferClaimSlotNameArea {
  promotionType = 'promotionType',
  activation = 'activation',
  promotion = 'promotion',
  newPromotion = 'newPromotion',
  warnMessages = 'warnMessages',
  earnPoints = 'earnPoints',
  infoMessages = 'infoMessages',
  price = 'price',
}
