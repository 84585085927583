import { Link, Typography } from '@mui/material';
import { getCspOffersListRoute } from 'features/offer/csp/routes';
import { Link as RouterLink } from 'react-router-dom';
import { MPGrid } from 'theme/ui-kit/grid';
import { ContentWrapper, TextList, TextListItem } from './controls';

const cspOffersLink = (
  <Link
    component={RouterLink}
    to={getCspOffersListRoute({})}
  >
    объекты с&nbsp;признаком «КСП:&nbsp;Отдых»
  </Link>
);

export const CspApplicationDescription = () => {
  return (
    <ContentWrapper
      container
      spacing={4}
    >
      <MPGrid>
        <Typography variant='subtitle1'>Кому подойдет программа КСП</Typography>
        <TextList>
          <TextListItem>
            <Typography>
              В текущем годe Вы НЕ планируете отдыхать по социальному заказу в&nbsp;объектах ОАО&nbsp;«РЖД»
              и&nbsp;по&nbsp;корпоративному заказу в&nbsp;объектах АО&nbsp;«РЖД-ЗДОРОВЬЕ»?
            </Typography>
          </TextListItem>
          <TextListItem>
            <Typography>
              Вы изучили {cspOffersLink} и решили приобрести в один из&nbsp;них&nbsp;путевку от&nbsp;7&nbsp;дней?
            </Typography>
          </TextListItem>
        </TextList>
      </MPGrid>

      <MPGrid>
        <Typography>
          Тогда подайте заявку и,&nbsp;при&nbsp;положительном решении Комиссии&nbsp;по&nbsp;распределению путевок
          в&nbsp;санаторно-курортные и&nbsp;оздоровительные учреждения, получите частичную компенсацию расходов&nbsp; на
          путевки в&nbsp;{cspOffersLink}
        </Typography>
      </MPGrid>

      <MPGrid>
        <Typography>
          Комиссия по распределению путевок в&nbsp;санаторно-курортные и&nbsp;оздоровительные учреждения проинформирует
          Вас о&nbsp;принятии решении в&nbsp;течение 30 дней
        </Typography>
      </MPGrid>
    </ContentWrapper>
  );
};
