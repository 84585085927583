import CorpOfferClaimEarnPointsSlot from 'features/offer/corp/claim/slots/earnPoints';
import ContentLoader from 'presentation/components/common/loader';
import { useRef } from 'react';
import OfferClaimLayout from '../../../../layouts/offerClaimLayout';
import { CorpOfferClaimActivationAdapter } from './adapters/activation';
import ActivityEndDateAdapter from './adapters/activityEndDate';
import { CorpOfferClaimEarnPointsAdapter } from './adapters/earnPoints';
import { CorpOfferClaimLeftCodesAdapter } from './adapters/leftCodes';
import { CorpOfferClaimInfoMessagesAdapter } from './adapters/messages/infoMessages';
import CorpOfferPromotionTypeAdapter from './adapters/messages/obtainingMethod';
import { CorpOfferClaimWarnMessagesAdapter } from './adapters/messages/warnMessages';
import NdflAdapter from './adapters/ndfl';
import OfferCodeAdapter from './adapters/offerCode';
import { CorpOfferClaimPriceAdapter } from './adapters/price';
import { CorpOfferClaimPromotionAdapter } from './adapters/promotion';
import { CorpOfferClaimPromotionNewAdapter } from './adapters/promotionNew';
import ClaimContentLayout from './layout/content';
import ClaimInfoLayout from './layout/info';
// import { CorpOfferClaimSubscriptionAdapter } from './adapters/subscription';
import useCorpOfferClaimContext from './provider/useCorpOfferClaimContext';
import {
  CorpOfferClaimActivationSlot,
  CorpOfferClaimInfoMessagesSlot,
  CorpOfferClaimNewPromotionSlot,
  CorpOfferClaimPriceSlot,
  CorpOfferClaimPromotionSlot,
  CorpOfferClaimPromotionTypeSlot,
  CorpOfferClaimWarnMessagesSlot,
} from './slots';

const CorpOfferClaim = () => {
  const { isFetching } = useCorpOfferClaimContext();
  const ref = useRef<any>();

  return isFetching ? (
    <ContentLoader />
  ) : (
    <OfferClaimLayout
      ref={ref}
      content={
        <ClaimContentLayout>
          <CorpOfferClaimPromotionTypeSlot>
            <CorpOfferPromotionTypeAdapter />
          </CorpOfferClaimPromotionTypeSlot>
          <CorpOfferClaimPriceSlot>
            <CorpOfferClaimPriceAdapter />
          </CorpOfferClaimPriceSlot>
          <CorpOfferClaimActivationSlot>
            <CorpOfferClaimActivationAdapter />
          </CorpOfferClaimActivationSlot>
          <CorpOfferClaimPromotionSlot>
            <CorpOfferClaimPromotionAdapter />
          </CorpOfferClaimPromotionSlot>
          <CorpOfferClaimNewPromotionSlot>
            <CorpOfferClaimPromotionNewAdapter />
          </CorpOfferClaimNewPromotionSlot>
          <CorpOfferClaimWarnMessagesSlot>
            <CorpOfferClaimWarnMessagesAdapter />
          </CorpOfferClaimWarnMessagesSlot>
          <CorpOfferClaimEarnPointsSlot>
            <CorpOfferClaimEarnPointsAdapter />
          </CorpOfferClaimEarnPointsSlot>
          <CorpOfferClaimInfoMessagesSlot>
            <CorpOfferClaimInfoMessagesAdapter />
          </CorpOfferClaimInfoMessagesSlot>
        </ClaimContentLayout>
        // <SlotsGridArea
        //   area={ESlotNameArea.subscriptions}
        //   key={ESlotNameArea.subscriptions}
        // >
        //   <CorpOfferSidebarSubscriptionAdapter />
        // </SlotsGridArea>,
      }
      skirt={
        <ClaimInfoLayout help={<NdflAdapter key='ndfl' />}>
          <OfferCodeAdapter key='code' />
          <ActivityEndDateAdapter key='endDate' />
          <CorpOfferClaimLeftCodesAdapter key='leftCodes' />
        </ClaimInfoLayout>
      }
    />
  );
};

export default CorpOfferClaim;
