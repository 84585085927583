import { User } from 'domain/model';
import Splitter from 'presentation/components/common/splitter';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { getPersonFullName } from 'presentation/utils';
import { HeaderUserShort } from '../short';
import { Email, Text } from './controls';

type HeaderUserFullProps = {
  readonly user: User;
  readonly isFetching?: boolean;
};

export const HeaderUserFull = (props: HeaderUserFullProps) => {
  const { user, isFetching } = props;

  return (
    <MPGrid
      container
      spacing={2}
      wrap='nowrap'
    >
      <MPGrid size={{ xs: 2.5 }}>
        <HeaderUserShort
          size='small'
          user={user}
          isFetching={isFetching}
        />
      </MPGrid>
      <MPGrid size={{ zero: 12, xs: 9.5 }}>
        <Text>{getPersonFullName(user)}</Text>
        <Splitter size={0.5} />
        <Email
          color='text.secondary'
          variant='body2'
          component='div'
          title={user.email}
        >
          {user.email}
        </Email>
      </MPGrid>
    </MPGrid>
  );
};
