import { CorpOfferShort, EOfferStatus, EOfferType, TradeOfferShort } from 'domain/model';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tradeOffersCollectionSelector } from '../store/selectors';
import { collectionSetState } from '../store/slice';
import { CmsTradeOfferCollection } from '../tradeOffers';
import { CmsOfferCollectionAdapterProps } from '../types';

/**
 * основной адаптер для коллекции ТП
 */
export const CmsTradeOfferCollectionCommonAdapter = (props: CmsOfferCollectionAdapterProps) => {
  const { collection, guid } = props;

  const { webAnalytics } = useWebAnalytics();
  const dispatch = useDispatch();

  const pageSize = 24;
  const collectionState = useSelector(tradeOffersCollectionSelector(guid, collection.id));
  const visibleCount = collectionState?.visibleCount ?? pageSize;
  const statuses: Nullable<EOfferStatus[]> = [EOfferStatus.Active];

  const onLoadMore = useCallback(() => {
    dispatch(
      collectionSetState({
        guid,
        offerType: EOfferType.TradeOffer,
        id: collection.id,
        visibleCount: visibleCount + pageSize,
      })
    );
  }, [dispatch, guid, collection.id, visibleCount, pageSize]);

  const onShowCard = useCallback(
    (offer: TradeOfferShort | CorpOfferShort) => webAnalytics.offerShow(offer.id),
    [webAnalytics]
  );

  return (
    <CmsTradeOfferCollection
      {...props}
      statuses={statuses}
      visibleCount={visibleCount}
      onLoadMore={onLoadMore}
      onShowCard={onShowCard}
    />
  );
};
