import { PartitionIcon } from 'components/common/partitionIcon';
import SeasonBackgroundImg from 'components/common/seasonBg';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPTab } from 'presentation/theme/ui-kit/tab';
import { CatalogTabInfo, ECatalogTab } from '../../types';
import { SeasonBg, StyledTabs } from './controls';

type CatalogTabsProps = {
  readonly tab: ECatalogTab;
  readonly tabs: CatalogTabInfo[];
  readonly onChangeTab: (newTab: ECatalogTab) => void;
};

export const CatalogTabs = ({ tab, tabs, onChangeTab }: CatalogTabsProps) => {
  return (
    <MPGrid
      container
      spacing={2}
      alignItems='center'
      justifyContent='space-between'
    >
      <MPGrid size={{ zero: 12, md: 'grow' }}>
        <StyledTabs
          value={tab}
          textColor='primary'
          variant='scrollable'
          scrollButtons={false}
          onChange={(event, tab) => onChangeTab(tab)}
        >
          {tabs.map(item => (
            <MPTab
              label={item.label}
              value={item.value}
              icon={<PartitionIcon type={item.value} />}
              iconPosition='start'
              size={'small'}
              key={item.value}
            />
          ))}
          <SeasonBg>
            <SeasonBackgroundImg />
          </SeasonBg>
        </StyledTabs>
      </MPGrid>
    </MPGrid>
  );
};
