import OfferListLayout from 'presentation/layouts/offerList';
import { ReactNode } from 'react';
import {
  TradeOfferListAsideAdapter,
  TradeOfferListBannersAdapter,
  TradeOfferListDataCommonAdapter,
  TradeOfferListDataUpcomingAdapter,
  TradeOfferListLoaderAdapter,
  TradeOfferListSortSelectAdapter,
  TradeOfferListStubAdapter,
  TradeOffersHeaderAdapter,
} from './adapters';
import { TradeOfferListContext } from './context';
import useTradeOfferListHandlers from './hooks/useTradeOfferList';

const Layout = OfferListLayout;
const Aside = <TradeOfferListAsideAdapter />;
const Stub = <TradeOfferListStubAdapter />;
const Loader = <TradeOfferListLoaderAdapter />;
const SortSelect = <TradeOfferListSortSelectAdapter />;

type TradeOfferListContainerProps = {
  readonly guid: UUID;
  readonly name: Nullable<string>;
  readonly categoryIds: Nullable<UUID[]>;
  readonly partnerId: Nullable<UUID>;
  readonly header?: ReactNode;
};

const TradeOfferListContainer = (props: TradeOfferListContainerProps) => {
  const { guid, name, categoryIds, partnerId, header } = props;

  const handlers = useTradeOfferListHandlers({ guid, name, categoryId: categoryIds, partnerId });

  return (
    <TradeOfferListContext.Provider value={handlers}>
      <Layout
        banners={<TradeOfferListBannersAdapter guid={guid} />}
        header={<TradeOffersHeaderAdapter header={header} />}
        aside={Aside}
        controls={SortSelect}
        stub={Stub}
        loader={Loader}
      >
        <TradeOfferListDataCommonAdapter guid={guid} />
        <TradeOfferListDataUpcomingAdapter guid={guid} />
      </Layout>
    </TradeOfferListContext.Provider>
  );
};

export default TradeOfferListContainer;
