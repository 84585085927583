import { Typography } from '@mui/material';
import { BookingOfferContact, PartnerContactData } from 'domain/model';
import Splitter from 'presentation/components/common/splitter';
import DangerouslyHtmlWrapper from 'presentation/components/common/wrappers/dangerouslyHtml';
import PartnerDeskContact from 'presentation/features/partnerDesk/components/contact';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import OfferDetailsTabContactsLayout from './layout';

type OfferDetailsTabContactsProps = {
  readonly contacts: readonly PartnerContactData[] | readonly BookingOfferContact[];
  readonly description?: Nullable<string>;
  readonly descriptionTitle?: string;
};

const Layout = OfferDetailsTabContactsLayout;

const OfferDetailsTabContacts = ({ contacts, description, descriptionTitle }: OfferDetailsTabContactsProps) => {
  return (
    <MPGrid
      container
      spacing={3}
    >
      <Layout>
        {contacts.map((contact, index) => (
          <MPGrid
            key={index}
            size={{
              xl: 6,
              lg: 6,
              sm: 12,
            }}
          >
            <PartnerDeskContact contact={contact} />
            <Splitter size={2} />
          </MPGrid>
        ))}
      </Layout>
      {description && (
        <Layout>
          <MPGrid>
            <Typography
              variant='subtitle1'
              mb={1.25}
            >
              {descriptionTitle}
            </Typography>
            <DangerouslyHtmlWrapper>
              <Typography variant='body1'>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </Typography>
            </DangerouslyHtmlWrapper>
          </MPGrid>
        </Layout>
      )}
    </MPGrid>
  );
};

export default OfferDetailsTabContacts;
