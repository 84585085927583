import { Fade } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { OfferDetailsTabContactsLayoutType } from './types';

const OfferDetailsTabContactsLayout: OfferDetailsTabContactsLayoutType = props => {
  const { children } = props;

  return (
    <Fade in>
      <MPGrid
        container
        spacing={5}
      >
        {children}
      </MPGrid>
    </Fade>
  );
};

export default OfferDetailsTabContactsLayout;
