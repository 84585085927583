import { BookingOfferListSearchParams } from '@/presentation/features/offer/booking/types';
import { CustomersApi } from '@privilege-frontend/api';
import {
  BookingOffer,
  BookingOfferShort,
  BookingOrder,
  BookingOrderRequest,
  BookingPriceRange,
  BookingServicesItem,
  Category,
  CategoryTree,
  EListResultTypes,
  EOrderRequestDiscriminator,
  Pageable,
  PriceRangeFilter,
} from 'domain/model';
import { categoriesBuildTree } from 'presentation/utils/category';
import { getBaseEndpoint } from '../openApi/utils';
import {
  api,
  EBookingOfferServicesTag,
  EOrderServicesTag,
  EUserServicesTag,
  OfferListRequest,
  OffersCountRequest,
} from './index';
import { getPageableFromResponseHeaders, getQueryErrorByAxiosError } from './utils';

export type BookingOfferCategoriesUsedRequest = {
  readonly name?: Nullable<string>;
  readonly onlyLeafCategories?: Nullable<boolean>;
};

const bookingOfferCategoriesTransformer = (categories: Category[]): CategoryTree =>
  categoriesBuildTree(categories.filter(({ parentId }) => !parentId)); // Возвращает только категории 1 уровня

export type BookingOffersRequestServices = {
  readonly services?: Nullable<UUID[]>;
};

export type BookingOffersRequestCompensation = {
  readonly rzdSocialPackage?: Nullable<boolean>;
};

export type BookingOffersRequest = BookingOffersRequestCompensation &
  PriceRangeFilter &
  BookingOffersRequestServices &
  OfferListRequest;

export type BookingOffersBaseRequest = PriceRangeFilter & {
  readonly query?: Nullable<string>;
  readonly categories?: Nullable<UUID[]>;
  readonly partnerId?: Nullable<UUID>;
  readonly isRzdSocialPackage?: Nullable<boolean>;
};

type BookingOffersListCategoryId = {
  categoryId: UUID;
};

export type BookingOffersListServicesRequest = BookingOffersListCategoryId &
  Pick<BookingOffersBaseRequest, 'query' | 'partnerId' | 'minPrice' | 'maxPrice' | 'isRzdSocialPackage'>;

export type BookingOffersListPriceRangeRequest = BookingOffersListCategoryId &
  Pick<BookingOffersBaseRequest, 'query' | 'isRzdSocialPackage'> &
  BookingOffersRequestServices;

export const bookingOfferApi = api.injectEndpoints({
  endpoints: builder => ({
    getBookingOfferCategories: builder.query<CategoryTree, {}>({
      queryFn: async (_, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerBookingOffersCategories(getBaseEndpoint(), {}, signal);
          return {
            data: bookingOfferCategoriesTransformer(data),
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),

    getBookingOfferCategoriesUsed: builder.query<Category[], BookingOfferCategoriesUsedRequest>({
      queryFn: async ({ name }, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerBookingOffersCategories(
            getBaseEndpoint(),
            {
              q: name,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),

    getBookingOfferList: builder.query<Pageable<BookingOfferShort>, BookingOffersRequest>({
      queryFn: async (
        { search, page, pageSize, sort = [], services, maxPrice, minPrice, rzdSocialPackage },
        { signal }
      ) => {
        const { name, categories, partnerId } = search;
        try {
          const response = await CustomersApi.listCustomerBookingOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
              page: 0,
              size: page * pageSize,
              sort,
              q: name,
              serviceCategory: services,
              bookingOfferCategory: categories,
              partnerId,
              minPrice,
              maxPrice,
              isRzdSocialPackage: rzdSocialPackage ? true : null,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<BookingOfferShort> = {
            data: response.data,
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EBookingOfferServicesTag.List],
    }),

    getBookingOffersCount: builder.query<number, OffersCountRequest>({
      queryFn: async ({ search }, { signal }) => {
        const { name } = search;
        try {
          const { data } = await CustomersApi.listCustomerBookingOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.Count,
              page: 0,
              size: 1,
              q: name,
            },
            signal
          );
          return {
            data: data?.[0]?.count ?? 0,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),

    getBookingOfferDetails: builder.query<BookingOffer, UUID>({
      queryFn: async (id, { signal }) => {
        try {
          const { data } = await CustomersApi.getCustomerBookingOfferById(getBaseEndpoint(), id, signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: (result, error, id) => [
        { type: EBookingOfferServicesTag.Details, id },
        EBookingOfferServicesTag.Details,
      ],
    }),

    createOrder: builder.mutation<BookingOrder, BookingOrderRequest>({
      queryFn: async (order, { signal }) => {
        try {
          const { data } = await CustomersApi.createCustomerOrders(
            getBaseEndpoint(),
            {
              ...order,
              discriminator: EOrderRequestDiscriminator.BookingOrderRequest,
            },
            signal
          );
          return {
            data: data[0] as BookingOrder,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      invalidatesTags: [EUserServicesTag.OrdersCount, EOrderServicesTag.Orders],
    }),
    // extremum price range
    getBookingPriceRange: builder.query<BookingPriceRange, BookingOffersListPriceRangeRequest>({
      queryFn: async ({ query, categoryId, services, isRzdSocialPackage }, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerBookingPriceRange(
            getBaseEndpoint(),
            {
              q: query,
              serviceCategory: services,
              bookingOfferCategory: categoryId ? [categoryId] : null,
              isRzdSocialPackage: isRzdSocialPackage ? true : null,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getAvailableBookingServices: builder.query<BookingServicesItem[], BookingOffersListServicesRequest>({
      queryFn: async ({ query, categoryId, partnerId, minPrice, maxPrice, isRzdSocialPackage }, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerBookingServiceCategories(
            getBaseEndpoint(),
            {
              q: query,
              bookingOfferCategory: categoryId ? [categoryId] : null,
              isRzdSocialPackage: isRzdSocialPackage ? true : null,
              minPrice,
              maxPrice,
              partnerId,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      keepUnusedDataFor: 0,
    }),
    getAllBookingServices: builder.query<
      BookingServicesItem[],
      Pick<BookingOfferListSearchParams, 'categoryId' | 'name'>
    >({
      queryFn: async ({ categoryId, name }, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerBookingServiceCategories(
            getBaseEndpoint(),
            {
              q: name,
              bookingOfferCategory: categoryId ? [categoryId] : null,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
  }),
});

export const {
  useGetBookingOfferListQuery,
  useGetBookingOffersCountQuery,
  useGetBookingOfferCategoriesQuery,
  useGetBookingOfferCategoriesUsedQuery,
  useGetBookingOfferDetailsQuery,
  useGetBookingPriceRangeQuery,
  useCreateOrderMutation,
  useGetAvailableBookingServicesQuery,
  useGetAllBookingServicesQuery,
} = bookingOfferApi;
