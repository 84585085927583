import { skipToken } from '@reduxjs/toolkit/query';
import { useGetTradeOfferCategoriesQuery, useGetTradeOfferCategoriesUsedQuery } from 'data/api/tradeOffer';
import ErrorHandler from 'data/network/errorHandler';
import { Category } from 'domain/model';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { tradeOfferListCategoryIdsSelector } from '../store/selectors';

const useCategoriesData = () => {
  const categoryIds = useSelector(tradeOfferListCategoryIdsSelector);
  const hasMultipleCategories = (categoryIds?.length ?? 0) > 1;
  const categoryId = hasMultipleCategories ? null : categoryIds?.[0];

  const {
    data: categories = null,
    isFetching: isCategoriesFetching,
    error: categoriesFetchError,
  } = useGetTradeOfferCategoriesQuery(hasMultipleCategories ? skipToken : {});

  const {
    data: categoriesUsedData = null,
    error: categoriesUsedFetchError,
    isFetching: isCategoriesUsedFetching,
  } = useGetTradeOfferCategoriesUsedQuery(
    hasMultipleCategories
      ? skipToken
      : {
          //запрашиваем листовые только если находимся внутри категории
          onlyLeafCategories: !!categoryId,
        }
  );

  useEffect(() => {
    if (categoriesFetchError) {
      ErrorHandler.handleHttpError(categoriesFetchError);
    }
    if (categoriesUsedFetchError) {
      ErrorHandler.handleHttpError(categoriesUsedFetchError);
    }
  }, [categoriesFetchError, categoriesUsedFetchError]);

  const isFetching = isCategoriesFetching || isCategoriesUsedFetching;

  //если на входе несколько категорий, то вернём пустые массивы
  //даже не смотря на то что выше прописан skipToken, потому что если запрос уже отработал ранее, то skipToken не очистит его результаты и они возьмутся из кэша rtk query
  if (hasMultipleCategories) {
    return {
      categories: null,
      subCategories: null,
      isCategoriesFetching: isFetching,
    };
  }

  let subCategories: Nullable<Category[]> = null;

  //если категория указана, то ищем из набора используемых
  if (categoryId) {
    subCategories = categoriesUsedData?.length
      ? categoriesUsedData.filter(category => category.parentId === categoryId)
      : null;
  } else {
    //иначе берём все рутовые
    subCategories = categoriesUsedData?.length
      ? categoriesUsedData.filter(category => !category.parentId).sort((c1, c2) => c1.name.localeCompare(c2.name))
      : null;
  }

  return {
    categories,
    subCategories,
    isCategoriesFetching: isFetching,
  };
};

export default useCategoriesData;
