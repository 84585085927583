/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GenCmsBannerId, GenCmsCollection, GenCmsCollectionId } from './data-contracts';
import { createRequest } from './http-client';

export const CmsCollectionApi = {
  /**
   * No description
   * * @tags CMS-COLLECTION
   * @name ListCollections
   * @summary Получение подборки
   * @request GET:/cms/collections
   * @secure
   */
  listCollections: (
    baseURL: string,
    query: {
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** список id коллекций */
      collectionIds?: GenCmsBannerId[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenCmsCollection[], void>({
      baseURL,
      path: `/cms/collections`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags CMS-COLLECTION
   * @name GetCollectionById
   * @summary Получение подборки
   * @request GET:/cms/collections/{collectionId}
   * @secure
   */
  getCollectionById: (baseURL: string, collectionId: GenCmsCollectionId, signal?: AbortSignal) =>
    createRequest<GenCmsCollection, void>({
      baseURL,
      path: `/cms/collections/${collectionId}`,
      method: 'GET',
      format: 'json',
      signal,
    }),
};
