import { Tab, Tabs } from '@mui/material';
import { PartnerDesk, ProductOffer } from 'domain/model';
import Splitter from 'presentation/components/common/splitter';
import { TabsWrapper } from 'presentation/components/common/wrappers/tabs';
import { PartnerDeskDetailsTabContacts } from 'presentation/features/partnerDesk/details/tabs';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import React from 'react';
import OfferDetailsTabCharacteristics from '../../../components/detailsTabs/characteristics';
import OfferDetailsTabDescription from '../../../components/detailsTabs/description';
import { EProductOfferDetailsTab } from '../utils';

type ProductOfferTabsProps = {
  readonly productOffer: ProductOffer;
  readonly partnerDesk: PartnerDesk;
  readonly tab: EProductOfferDetailsTab;
  readonly characteristicTabRef: React.RefObject<any>;
  readonly onChange: (event: React.SyntheticEvent, newTab: EProductOfferDetailsTab) => void;
};

const ProductOfferTabs = (props: ProductOfferTabsProps) => {
  const { productOffer, tab, characteristicTabRef, onChange, partnerDesk } = props;

  return (
    <TabsWrapper>
      <Tabs
        value={tab}
        textColor='secondary'
        variant='scrollable'
        onChange={onChange}
        scrollButtons={false}
      >
        <Tab
          label='Описание'
          value={EProductOfferDetailsTab.Description}
        />
        <Tab
          ref={characteristicTabRef}
          label='Характеристики'
          value={EProductOfferDetailsTab.Characteristics}
        />
        <Tab
          label='Контакты'
          value={EProductOfferDetailsTab.Contacts}
        />
      </Tabs>
      <Splitter size={2.5} />
      <MPGrid size={12}>
        {tab === EProductOfferDetailsTab.Description && (
          <OfferDetailsTabDescription description={productOffer.description} />
        )}
        {tab === EProductOfferDetailsTab.Characteristics && (
          <OfferDetailsTabCharacteristics text={productOffer.otherAttributes ?? ''} />
        )}
        {tab === EProductOfferDetailsTab.Contacts && partnerDesk.contacts && (
          <PartnerDeskDetailsTabContacts contacts={partnerDesk.contacts} />
        )}
      </MPGrid>
    </TabsWrapper>
  );
};

export default ProductOfferTabs;
