import { CategoriesApi, CustomersApi } from '@privilege-frontend/api';
import {
  AspOffer,
  AspOfferShort,
  Category,
  CategoryTree,
  EListResultTypes,
  ETradeOfferSortType,
  Pageable,
} from 'domain/model';
import { categoriesBuildTree } from 'presentation/utils/category';
import { getBaseEndpoint } from '../openApi/utils';
import { api, EAspOfferServicesTag, OfferListRequest, OffersCountRequest } from './index';
import {
  defaultAspOfferActiveStatuses,
  defaultAspOfferPaymentTypes,
  defaultAspOfferValidStatuses,
  getPageableFromResponseHeaders,
  getQueryErrorByAxiosError,
} from './utils';

export type AspOfferSearchRequest = {
  readonly search: {
    readonly name: Nullable<string>;
  };
  readonly pageSize: number;
};

export type AspOfferCategoriesUsedRequest = {
  readonly name?: Nullable<string>;
  readonly onlyLeafCategories?: Nullable<boolean>;
};

export type AspOfferCategoriesUsedTreeRequest = {
  readonly guid: UUID;
};

export type GetAspOfferDetailsRequest = {
  readonly id: UUID;
};

export const aspOfferApi = api.injectEndpoints({
  endpoints: builder => ({
    getAspOfferCategories: builder.query<Category[], { ids?: UUID[] }>({
      queryFn: async ({ ids }, { signal }) => {
        try {
          const { data } = await CategoriesApi.getTradeOfferCategories(
            getBaseEndpoint(),
            {
              category: ids,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getAspOfferCategoriesUsed: builder.query<Category[], AspOfferCategoriesUsedRequest>({
      queryFn: async ({ name, onlyLeafCategories }, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerTradeOfferCategories(
            getBaseEndpoint(),
            {
              q: name,
              status: defaultAspOfferValidStatuses,
              paymentType: defaultAspOfferPaymentTypes,
              onlyLeafCategories,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getAspOfferCategoriesUsedTree: builder.query<CategoryTree, AspOfferCategoriesUsedTreeRequest>({
      queryFn: async (_, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerTradeOfferCategories(
            getBaseEndpoint(),
            {
              status: defaultAspOfferValidStatuses,
              paymentType: defaultAspOfferPaymentTypes,
              onlyLeafCategories: false,
            },
            signal
          );
          return {
            data: categoriesBuildTree(data),
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getAspOfferList: builder.query<Pageable<AspOfferShort>, OfferListRequest>({
      queryFn: async ({ search, page, pageSize, sort = [] }, { signal }) => {
        const { name, categories, statuses = defaultAspOfferActiveStatuses, partnerId } = search;
        try {
          const response = await CustomersApi.listCustomerTradeOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
              page: 0,
              size: page * pageSize,
              sort,
              q: name,
              status: statuses,
              category: categories,
              partnerId,
              paymentType: defaultAspOfferPaymentTypes,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<AspOfferShort> = {
            data: response.data,
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EAspOfferServicesTag.List],
    }),
    getAspOffersCount: builder.query<number, OffersCountRequest>({
      queryFn: async ({ search }, { signal }) => {
        const { name, categories } = search;
        try {
          const { data } = await CustomersApi.listCustomerTradeOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.Count,
              page: 0,
              size: 1,
              q: name,
              status: defaultAspOfferValidStatuses,
              category: categories,
              paymentType: defaultAspOfferPaymentTypes,
            },
            signal
          );
          return {
            data: data?.[0]?.count ?? 0,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getAspOfferSearch: builder.query<Pageable<AspOfferShort>, AspOfferSearchRequest>({
      keepUnusedDataFor: 0,
      queryFn: async ({ search, pageSize }, { signal }) => {
        const { name } = search;
        try {
          const response = await CustomersApi.listCustomerTradeOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
              page: 0,
              size: pageSize,
              sort: [ETradeOfferSortType.Default],
              q: name,
              status: defaultAspOfferValidStatuses,
              paymentType: defaultAspOfferPaymentTypes,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<AspOfferShort> = {
            data: response.data,
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getAspOfferDetails: builder.query<AspOffer, GetAspOfferDetailsRequest>({
      queryFn: async ({ id }, { signal }) => {
        try {
          const { data } = await CustomersApi.getCustomerTradeOfferById(getBaseEndpoint(), id, signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: (result, error, args) => [
        { type: EAspOfferServicesTag.Details, id: args.id },
        EAspOfferServicesTag.Details,
      ],
    }),
  }),
});

export const {
  useGetAspOfferListQuery,
  useGetAspOffersCountQuery,
  useGetAspOfferSearchQuery,
  useGetAspOfferCategoriesQuery,
  useGetAspOfferCategoriesUsedQuery,
  useGetAspOfferCategoriesUsedTreeQuery,
  useGetAspOfferDetailsQuery,
} = aspOfferApi;
