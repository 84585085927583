import {
  OptionalDictionaryValue,
  ProductAttributeGroup,
  ProductAttributeType,
  ProductCategoryAttribute,
  ProductOffer,
  ProductOfferShort,
} from 'domain/model';

import { ProductAttributeValueLinkedProductIds, ValueExtendedProductCategoryAttribute } from './types';

export const mergedAttributeSeparator = '/';

export type GroupedByProductIdCategoryAttribute = ProductCategoryAttribute & {
  readonly valuesGroup: ProductAttributeValueLinkedProductIds[];
};

export type WithExtendedAttributesProductOffer = {
  readonly product: ProductOffer | ProductOfferShort;
  readonly attributes: ValueExtendedProductCategoryAttribute[];
};

/**
 * атрибут принадлежит группе
 * **/
export type GroupedAttribute = {
  readonly group: Nullable<ProductAttributeGroup>;
  readonly attributes: ValueExtendedProductCategoryAttribute[];
};

/**
 * атрибуты которые принадлежит группе или не принадлежит
 * **/
export type GroupAttributesOrExtendedAttribute = GroupedAttribute | ValueExtendedProductCategoryAttribute;

/**
 * варианты товара со сгруппированными атрибутами (при наличии group.id), иначе атрибуты остаются как есть
 * **/
export type WithGroupedExtendedAttributesProductOffer = {
  readonly product: WithExtendedAttributesProductOffer['product'];
  readonly groupedAttributes: GroupAttributesOrExtendedAttribute[];
};

export type WithMergedExtendedAttributesProductOffer = WithGroupedExtendedAttributesProductOffer & {
  readonly mergedAttributes: ValueExtendedProductCategoryAttribute[];
  readonly fileAttributes: ValueExtendedProductCategoryAttribute[];
};

// получение варианта с атрибутами, обогащенными данными из категорий, здесь также задается порядок атрибутов
export const getVariantsWithExtendedAttributes = (
  variants: (ProductOffer | ProductOfferShort)[]
): WithExtendedAttributesProductOffer[] => {
  // чистим от вариантов без атрибутов
  const onlyWithAttributesVariants = variants.filter(v => v.attributes?.length);

  // вернет все атрибуты категорий для товара, с values
  const extendedVariants = onlyWithAttributesVariants.map(v => {
    const extendedByCategoryAttributes: ValueExtendedProductCategoryAttribute[] = (v.category?.attributes ?? []).map(
      ca => {
        const variantAttribute = v?.attributes?.find(va => {
          return va.attribute.id === ca.attribute.id;
        });

        if (variantAttribute) {
          return {
            ...ca,
            values: variantAttribute.values,
          };
        }

        return {
          ...ca,
          values: [],
        };
      }
    );

    return {
      product: v,
      attributes: extendedByCategoryAttributes,
    };
  });
  return extendedVariants;
};

export function isGroupedAttributeGuard(attribute: GroupAttributesOrExtendedAttribute): attribute is GroupedAttribute {
  return (attribute as GroupedAttribute).group !== undefined;
}

// обернет все атрибуты в группу, если у них есть group.id, если нет то оставит атрибут как есть (group.id === null)
export const getVariantsWithGroupedAttributes = (
  variants: WithExtendedAttributesProductOffer[]
): WithGroupedExtendedAttributesProductOffer[] => {
  return variants.map(v => {
    const groupedAttributes: GroupAttributesOrExtendedAttribute[] = v.attributes.reduce((acc, va) => {
      const prevAttributeSameCurrentGroupId = acc.findIndex(a => {
        if (isGroupedAttributeGuard(a)) {
          return a.group?.id === va.attribute?.group?.id;
        }

        if (!isGroupedAttributeGuard(a)) {
          return a.attribute.group?.id === va.attribute?.group?.id;
        }
      });

      // не найден атрибут с таким group.id или group отсутствует
      if (prevAttributeSameCurrentGroupId === -1) {
        const hasGroupId = !!va.attribute?.group?.id;
        if (hasGroupId) {
          return [
            ...acc,
            {
              group: va.attribute.group as ProductAttributeGroup,
              attributes: [va],
            },
          ];
        }

        return [...acc, va];
      }

      // если найденный атрибут является группой
      if (isGroupedAttributeGuard(acc[prevAttributeSameCurrentGroupId])) {
        const updatedAcc = acc.map((aItem, aIndex) => {
          if (prevAttributeSameCurrentGroupId === aIndex) {
            return {
              ...aItem,
              group: va.attribute.group as ProductAttributeGroup,
              attributes: [...(aItem as GroupedAttribute).attributes, va],
            };
          }

          return aItem;
        });

        return updatedAcc;
      } else {
        const updatedAcc = acc.map((aItem, aIndex) => {
          if (prevAttributeSameCurrentGroupId === aIndex) {
            return {
              ...aItem,
              group: va.attribute.group as ProductAttributeGroup,
              attributes: [aItem as ValueExtendedProductCategoryAttribute, va],
            };
          }

          return aItem;
        });

        return updatedAcc;
      }
    }, [] as GroupAttributesOrExtendedAttribute[]);

    return {
      product: v.product,
      groupedAttributes: groupedAttributes,
    };
  });
};

function computeMergedValues(
  mainAttrValues: Nullable<OptionalDictionaryValue[]>,
  otherAttrValues: Nullable<OptionalDictionaryValue[]>[]
) {
  const result: Nullable<OptionalDictionaryValue[]> = otherAttrValues.reduce((acc, oav) => {
    const res: OptionalDictionaryValue[] = [
      {
        dictionaryValue: mainAttrValues?.[0]?.dictionaryValue ? mainAttrValues[0].dictionaryValue : null,
        value:
          acc && acc[0]?.value
            ? acc[0]?.value + (oav?.[0]?.value ? `${mergedAttributeSeparator}${oav?.[0]?.value}` : '')
            : '',
      },
    ];

    return res;
  }, mainAttrValues);

  return result;
}

export const getVariantsWithMergedByGroupAttributes = (
  variants: WithGroupedExtendedAttributesProductOffer[]
): WithMergedExtendedAttributesProductOffer[] => {
  const withMergedAttributesVariants: WithMergedExtendedAttributesProductOffer[] = variants.map(v => {
    // фильтруем от типов файл, чтобы не объединять name с ним
    const withoutFileTypeAttributes: GroupAttributesOrExtendedAttribute[] = v.groupedAttributes.map(vga => {
      if (isGroupedAttributeGuard(vga)) {
        return {
          ...vga,
          attributes: vga.attributes.filter(vgaf => vgaf.attribute.type !== ProductAttributeType.File),
        };
      }

      return vga;
    });

    /* извлекаем атрибуты из группы атрибутов файлов, id группа нам не нужна, иначе возникнет коллизия с одинаковыми id (мы используем group.id для примитивов mergedAttributes) */
    const fileTypeGroupedAndExtendedAttributes: GroupAttributesOrExtendedAttribute[] = v.groupedAttributes.map(vga => {
      if (isGroupedAttributeGuard(vga)) {
        return {
          ...vga,
          attributes: vga.attributes.filter(vgaf => vgaf.attribute.type === ProductAttributeType.File),
        };
      }

      return vga;
    });

    const fileAttributes = fileTypeGroupedAndExtendedAttributes
      .flatMap(fta => {
        if (isGroupedAttributeGuard(fta)) {
          return fta.attributes;
        } else {
          return fta;
        }
      })
      .filter(f => f.attribute.type === ProductAttributeType.File);
    /**/

    const primitiveMergedAttributes = withoutFileTypeAttributes.map(ga => {
      if (isGroupedAttributeGuard(ga)) {
        // берем ведущий required аттрибут либо первый если required аттрибуты отсутствуют
        const mainAttribute = ga.attributes.find(a => a.required) ?? ga.attributes[0];

        // фильтруем оставщиеся атрибуты от ведущего и типов файл
        const withoutMainAndFileAttributes = ga.attributes.filter(a => a.attribute.id !== mainAttribute.attribute.id);

        /** производный атрибут, имеющийся attribute.id будет перезаписан на group.id   **/
        const mergedAttribute: ValueExtendedProductCategoryAttribute = {
          ...mainAttribute,
          attribute: {
            ...mainAttribute.attribute,
            id: (mainAttribute.attribute.group as ProductAttributeGroup).id,
            name:
              ga?.group?.value ??
              mainAttribute.attribute
                .name /*+ withoutMainAndFileAttributes.reduce((acc, wma) => acc + `/${wma.attribute.name}`, '')*/,
            description:
              mainAttribute.attribute.description +
              withoutMainAndFileAttributes.reduce((acc, wma) => acc + `/${wma.attribute.description}`, ''),
          },
          values: computeMergedValues(
            mainAttribute.values,
            withoutMainAndFileAttributes.map(wma => wma.values)
          ),
        };

        return mergedAttribute;
      } else {
        return ga;
      }
    });

    const mergedAttributes = [...primitiveMergedAttributes];

    return {
      // id атрибута перезаписано значением из group.id
      mergedAttributes,
      // id атрибута НЕ перезаписано значением из group.id
      fileAttributes,
      groupedAttributes: v.groupedAttributes,
      product: v.product,
    };
  });

  return withMergedAttributesVariants;
};

const makeMergedAttributes = (
  variants: (ProductOffer | ProductOfferShort)[]
): WithMergedExtendedAttributesProductOffer[] => {
  const extended = getVariantsWithExtendedAttributes(variants);
  const grouped = getVariantsWithGroupedAttributes(extended);
  return getVariantsWithMergedByGroupAttributes(grouped);
};

export default makeMergedAttributes;
