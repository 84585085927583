import { ButtonProps } from '@mui/material';
import { EOfferStatus, ProductOfferShort } from 'domain/model';
import { WarnMessage } from 'presentation/components/common/messages';
import { FCC, MouseEventHandler, useCallback } from 'react';
import { useHistory } from 'react-router';
import CartProviderContainer from '../../../cart/cartProvider/container';
import CartCounter from '../../../cart/components/counter';
import { getCartRootRoute } from '../../../cart/entry';
import ProductOfferAddToCartButton from '../components/addToCartButton';
import { ProductOfferAddToCartLabelProps } from '../components/addToCartButton/types';
import ProductOfferOutOfStockCartButton from '../components/outOfStockCartButton';
import { ControlsWrapper, Wrapper } from './controls';

type ProductOfferCartCounterContainerProps = {
  readonly product: ProductOfferShort;
  readonly size?: ButtonProps['size'];
  readonly stretch?: boolean;
  readonly label: FCC<ProductOfferAddToCartLabelProps>;
};

const ProductOfferCartCounterContainer = (props: ProductOfferCartCounterContainerProps) => {
  const { product, stretch, size = 'small', label } = props;

  const history = useHistory();

  const { stock, status } = product;

  const isOutOfStock = stock === 0 || status === EOfferStatus.OutOfStock;

  const onOpenCart = useCallback(() => history.push(getCartRootRoute()), [history]);

  const onClickPrevent: MouseEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
  };

  const getSizeCounter = () => {
    switch (size) {
      case 'small':
        return 'micro';
      case 'medium':
        return 'small';
      case 'large':
        return 'medium';
    }
  };

  return (
    <CartProviderContainer product={product}>
      {({ cartItem, isItemFetching, decrement, increment, updateItem, isOutOfQuantity }) => {
        const quantity = cartItem?.quantity ?? 0;
        const isEmpty = quantity === 0;

        const onClick = () => {
          if (isItemFetching) {
            return;
          }
          if (quantity) {
            onOpenCart();
          } else {
            increment();
          }
        };

        return (
          <Wrapper onClick={onClickPrevent}>
            <ControlsWrapper stretch={stretch}>
              {isOutOfStock ? (
                <ProductOfferOutOfStockCartButton
                  size={size}
                  label='Нет в наличии'
                />
              ) : (
                <ProductOfferAddToCartButton
                  isEmpty={isEmpty}
                  isFetching={isEmpty && isItemFetching}
                  label={label}
                  size={size}
                  disabled={isEmpty && isItemFetching}
                  onClick={onClick}
                />
              )}
              <CartCounter
                count={quantity}
                isEmpty={isEmpty}
                loading={isItemFetching}
                disabled={isItemFetching}
                max={stock ?? 0}
                size={getSizeCounter()}
                onDecrement={decrement}
                onIncrement={increment}
                onChange={count => updateItem(count)}
              />
            </ControlsWrapper>
            <WarnMessage
              show={isOutOfQuantity}
              textAlign='right'
              text={`Есть: ${cartItem?.offer?.stock}`}
            />
          </Wrapper>
        );
      }}
    </CartProviderContainer>
  );
};

export default ProductOfferCartCounterContainer;
