import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { ApiResponsePageable } from 'api/types';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { EOfferStatus, EPaymentType } from 'domain/model';
import { getBaseEndpoint } from 'openApi/utils';

export const getQueryErrorByAxiosError = (axiosError: unknown) => {
  const err = axiosError as AxiosError;
  return {
    data: undefined,
    error: {
      status: err.response?.status,
      data: err.response?.data,
      request: { responseURL: err.request?.responseURL },
    },
  };
};

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, AxiosResponse> =>
  async ({ baseURL, url, method, params, data, headers, transformResponse, signal: originalSignal }, { signal }) => {
    const baseUrl = baseURL ?? getBaseEndpoint();
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        params,
        data,
        headers,
        transformResponse,
        signal: originalSignal ?? signal,
      });
      return {
        data: result,
      };
    } catch (axiosError) {
      return getQueryErrorByAxiosError(axiosError);
    }
  };

export const getPageableFromResponseHeaders = (response: AxiosResponse): ApiResponsePageable => {
  const { headers } = response;
  return {
    pageCount: parseInt(headers['x-paging-page-count'], 10),
    totalCount: parseInt(headers['x-paging-total-count'], 10),
    page: parseInt(headers['x-paging-page'], 10),
    pageSize: parseInt(headers['x-paging-page-size'], 10),
  };
};

export const defaultTradeOfferActiveStatuses: EOfferStatus[] = [EOfferStatus.Active];

export const defaultTradeOfferValidStatuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.Upcoming];

export const defaultTradeOfferPaymentTypes: EPaymentType[] = [EPaymentType.Free];

export const defaultCorpOfferActiveStatuses: EOfferStatus[] = [EOfferStatus.Active];

export const defaultCorpOfferValidStatuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.Upcoming];

export const defaultAspOfferActiveStatuses: EOfferStatus[] = [EOfferStatus.Active];

export const defaultAspOfferValidStatuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.Upcoming];

export const defaultAspOfferPaymentTypes: EPaymentType[] = [EPaymentType.AspRzdRub];

export const defaultBookingOfferValidStatuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.Upcoming];
