import { Divider, Typography } from '@mui/material';
import { BookingOffer, BookingOfferShort, BookingOrderOffer } from 'domain/model';
import Splitter from 'presentation/components/common/splitter';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FC } from 'react';

export const getBookingPeriodHelperText = (offer: BookingOffer | BookingOfferShort | BookingOrderOffer) => `
Выберите периоды, в которые планируете свой отдых. Для начала выбора периода нажмите на дату, в которую планируете въезд. 
Для завершения выбора периода выберите дату выезда. При однодневном заселении необходимо выбрать даты последовательно. 
Отметьте количество ${getBookingSlotLabel(offer).toLowerCase()} для заселения. 
`;

export const getBookingDaysHelperText = (offer: BookingOffer | BookingOfferShort | BookingOrderOffer) => `
Выберите даты, в которые планируете посетить услуги. Даты можно выбирать не последовательно. 
Для получения услуги необходимо выбрать как минимум одну дату. 
Отметьте количество ${getBookingSlotLabel(offer).toLowerCase()} для получения услуги в выбранную дату.
`;

export const countWidth = '104px';

export const getBookingSlotLabel = (offer: BookingOffer | BookingOfferShort | BookingOrderOffer) => {
  return offer.rzdSocialPackage === true ? 'Номеров' : 'Гостей';
};

type TableHeaderProps = {
  readonly bookingOffer: BookingOffer;
};

export const TableHeader: FC<TableHeaderProps> = ({ bookingOffer }) => (
  <MPGrid
    container
    justifyContent='space-between'
  >
    <Splitter size={2} />
    <MPGrid>
      <Typography
        variant='body2'
        color='textSecondary'
      >
        Выбранные даты
      </Typography>
    </MPGrid>
    <MPGrid width={countWidth}>
      <Typography
        variant='body2'
        color='textSecondary'
      >
        {getBookingSlotLabel(bookingOffer)}
      </Typography>
    </MPGrid>
    <Splitter size={1} />
    <MPGrid size={12}>
      <Divider />
    </MPGrid>
    <Splitter size={1.5} />
  </MPGrid>
);
