import { Typography, useMediaQuery } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { toRubCurrency } from '../../../../../../utils/currency';
import { DiscountValue, OriginalPrice } from '../../../controls';
import { formatToSale } from '../../../utils';

type PricesProps = {
  readonly originalPrice: Nullable<number>;
  readonly salePercent: Nullable<number>;
  readonly price: Nullable<number>;
};

const Prices = ({ price, originalPrice, salePercent }: PricesProps) => {
  const isOriginalPriceVisible = originalPrice && price && originalPrice > price;
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <MPGrid
      container
      size='auto'
      spacing={1}
      alignItems={isMdUp ? 'flex-start' : 'center'}
    >
      <MPGrid size={{ md: 12 }}>
        <Typography variant='h3'>{toRubCurrency(price)}</Typography>
      </MPGrid>
      {isOriginalPriceVisible && (
        <MPGrid
          container
          size='auto'
        >
          <MPGrid>
            <OriginalPrice
              variant='body1'
              color='textSecondary'
            >
              {toRubCurrency(originalPrice)}
            </OriginalPrice>
          </MPGrid>
          {salePercent && (
            <MPGrid>
              <DiscountValue
                variant='body1'
                color='primary.main'
              >
                {formatToSale(salePercent)}
              </DiscountValue>
            </MPGrid>
          )}
        </MPGrid>
      )}
    </MPGrid>
  );
};

export default Prices;
