/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** файл + метаданные для сохранения во временную область */
export interface GenStoragePreloadRequest {
  /**
   * Файл
   * @format binary
   */
  file: File;
}

/**
 * Список файлов для копирования
 * @minItems 1
 */
export type GenStorageCopyRequest = GenStorageCopyRequestItem[];

/** Элемент запроса на копирование файла */
export interface GenStorageCopyRequestItem {
  /** Путь к файлy src */
  sourcePath: string;
  /** Путь к файлу target */
  targetPath: string;
  /**
   * Доступ для невторизованных пользователей
   * @default false
   */
  public: boolean | null;
}

/** Дескриптор файла в хранилище */
export interface GenStorageFileInfo {
  /** Путь к файлу в хранилище (bucket/name) Можно использовать как id файла в хранилище */
  path: string;
  /** Bucket */
  bucket: string;
  /** оригинальное имя файла, в фс пользовательского устройства */
  originFileName: string | null;
  /** имя файла */
  name: string;
  /** тип содержимого */
  contentType: string | null;
  /**
   * ИД залившего пользователя
   * @format uuid
   */
  creatorId: string | null;
  /** Доступ для невторизованных пользователей */
  public: boolean;
  /**
   * размер файла
   * @format int64
   */
  size: number;
  /** Метаданные файла */
  metadata: Record<string, string> | null;
}

/** Тип выгрузки */
export enum GenStorageDownloadType {
  Inline = 'inline',
  Attachment = 'attachment',
}

/** Стандартный ответ при негативном сценарии */
export interface GenStorageError {
  /** Код ошибки(например PRODUCT_NOT_FOUND) */
  code: string;
  /** Сообщение */
  message: string;
  /** Доп информация */
  description: string | null;
}
