import { CmsContainersApi, CollectionsApi, CustomersApi, PartnerDesksApi } from '@privilege-frontend/api';
import {
  AspOfferShort,
  BookingOfferShort,
  Category,
  CorpOfferShort,
  EBookingOfferSortType,
  ECorpOfferSortType,
  EListResultTypes,
  EOfferStatus,
  EProductOfferSortType,
  ETradeOfferSortType,
  Pageable,
  PartnerDesk,
  PartnerNameMap,
  PartnerWindow,
  ProductOfferShort,
  TradeOfferShort,
} from 'domain/model';
import { getBaseEndpoint } from 'openApi/utils';
import {
  api,
  EAspOfferServicesTag,
  EBookingOfferServicesTag,
  ECorpOfferServicesTag,
  EProductOfferServicesTag,
  ETradeOfferServicesTag,
} from './index';
import {
  defaultAspOfferPaymentTypes,
  defaultTradeOfferPaymentTypes,
  getPageableFromResponseHeaders,
  getQueryErrorByAxiosError,
} from './utils';

export type PartnerOfferListRequest<
  S extends ETradeOfferSortType | ECorpOfferSortType | EProductOfferSortType | EBookingOfferSortType
> = {
  readonly partnerId: UUID;
  readonly categoryId?: Nullable<UUID>;
  readonly sort: Nullable<S[]>;
  readonly statuses?: EOfferStatus[];
  readonly pageSize: number;
  readonly page: number;
};

export type PartnerOfferCategoriesUsedRequest = {
  readonly partnerId: UUID;
  readonly statuses?: EOfferStatus[];
};

export type PartnerDesksListRequest = {
  readonly page: number;
  readonly pageSize: number;
  readonly query?: Nullable<string>;
  readonly name?: Nullable<string[]>;
};

export type PartnerTestPageRequest = {
  readonly partnerId: UUID;
};

const defaultPartnerOfferValidStatuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.OutOfStock];

export const partnerApi = api.injectEndpoints({
  endpoints: builder => ({
    getPartnerTradeOfferList: builder.query<Pageable<TradeOfferShort>, PartnerOfferListRequest<ETradeOfferSortType>>({
      queryFn: async (
        { statuses = defaultPartnerOfferValidStatuses, partnerId, categoryId, page, pageSize, sort = [] },
        { signal }
      ) => {
        try {
          const response = await CustomersApi.listCustomerTradeOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
              page: 0,
              size: page * pageSize,
              sort,
              status: statuses,
              category: categoryId ? [categoryId] : null,
              partnerId,
              paymentType: defaultTradeOfferPaymentTypes,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<TradeOfferShort> = {
            data: response.data,
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: (result, error, args) => [{ type: ETradeOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerTradeOfferCategoriesUsed: builder.query<Category[], PartnerOfferCategoriesUsedRequest>({
      queryFn: async ({ partnerId, statuses }, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerTradeOfferCategories(
            getBaseEndpoint(),
            {
              partnerId,
              status: statuses,
              paymentType: defaultTradeOfferPaymentTypes,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: (result, error, args) => [{ type: ETradeOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerAspOfferList: builder.query<Pageable<AspOfferShort>, PartnerOfferListRequest<ETradeOfferSortType>>({
      queryFn: async (
        { statuses = defaultPartnerOfferValidStatuses, partnerId, categoryId, page, pageSize, sort = [] },
        { signal }
      ) => {
        try {
          const response = await CustomersApi.listCustomerTradeOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
              page: 0,
              size: page * pageSize,
              sort,
              status: statuses,
              category: categoryId ? [categoryId] : null,
              partnerId,
              paymentType: defaultAspOfferPaymentTypes,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<AspOfferShort> = {
            data: response.data,
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: (result, error, args) => [{ type: EAspOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerAspOfferCategoriesUsed: builder.query<Category[], PartnerOfferCategoriesUsedRequest>({
      queryFn: async ({ partnerId, statuses }, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerTradeOfferCategories(
            getBaseEndpoint(),
            {
              partnerId,
              status: statuses,
              paymentType: defaultAspOfferPaymentTypes,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: (result, error, args) => [{ type: EAspOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerProductOfferList: builder.query<
      Pageable<ProductOfferShort>,
      PartnerOfferListRequest<EProductOfferSortType>
    >({
      queryFn: async (
        { statuses = defaultPartnerOfferValidStatuses, partnerId, categoryId, page, pageSize, sort = [] },
        { signal }
      ) => {
        try {
          const response = await CustomersApi.listCustomerProductOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
              page: 0,
              size: page * pageSize,
              sort,
              status: statuses,
              category: categoryId ? [categoryId] : null,
              partnerId,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<ProductOfferShort> = {
            data: response.data,
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: (result, error, args) => [{ type: EProductOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerCorpOfferList: builder.query<Pageable<CorpOfferShort>, PartnerOfferListRequest<ECorpOfferSortType>>({
      queryFn: async (
        { statuses = defaultPartnerOfferValidStatuses, partnerId, categoryId, page, pageSize, sort = [] },
        { signal }
      ) => {
        try {
          const response = await CustomersApi.listCustomerCorpOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
              page: 0,
              size: page * pageSize,
              sort,
              status: statuses,
              category: categoryId ? [categoryId] : null,
              partnerId,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<CorpOfferShort> = {
            data: response.data,
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: (result, error, args) => [{ type: ECorpOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerCorpOfferCategoriesUsed: builder.query<Category[], PartnerOfferCategoriesUsedRequest>({
      queryFn: async ({ partnerId, statuses }, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerCorpOfferCategories(
            getBaseEndpoint(),
            {
              partnerId,
              status: statuses,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: (result, error, args) => [{ type: ECorpOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerBookingOfferList: builder.query<
      Pageable<BookingOfferShort>,
      PartnerOfferListRequest<EBookingOfferSortType>
    >({
      queryFn: async ({ page, pageSize, sort = [], partnerId, categoryId }, { signal }) => {
        try {
          const response = await CustomersApi.listCustomerBookingOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
              page: 0,
              size: page * pageSize,
              sort,
              bookingOfferCategory: categoryId ? [categoryId] : null,
              partnerId,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<BookingOfferShort> = {
            data: response.data,
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: (result, error, args) => [{ type: EBookingOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerBookingOfferCategoriesUsed: builder.query<
      Category[],
      Pick<PartnerOfferCategoriesUsedRequest, 'partnerId'>
    >({
      queryFn: async ({ partnerId }, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerBookingOffersCategories(
            getBaseEndpoint(),
            {
              partnerId,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: (result, error, args) => [{ type: EBookingOfferServicesTag.ByPartnerList, id: args.partnerId }],
    }),

    getPartnerDesksCollection: builder.query<PartnerDesk[], UUID>({
      queryFn: async (id, { signal }) => {
        try {
          const { data } = await CollectionsApi.getCustomerPartnerDeskCollection(getBaseEndpoint(), id, signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),

    getPartnerDesk: builder.query<PartnerDesk, UUID>({
      queryFn: async (id, { signal }) => {
        try {
          const { data } = await PartnerDesksApi.getPartnerDeskById(getBaseEndpoint(), id, signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),

    getPartnerDesks: builder.query<Pageable<PartnerDesk>, PartnerDesksListRequest>({
      queryFn: async ({ page, pageSize, query, name }, { signal }) => {
        try {
          const response = await CustomersApi.listCustomerPartnerDesks(
            getBaseEndpoint(),
            {
              page: page - 1,
              size: pageSize,
              partnerName: name,
              q: query,
            },
            signal
          );

          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<PartnerDesk> = {
            data: response.data,
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),

    getPartnerPage: builder.query<PartnerWindow, UUID>({
      queryFn: async (partnerId, { signal }) => {
        try {
          const { data } = await CmsContainersApi.listCustomerContainers(
            getBaseEndpoint(),
            { page: 0, size: 100000, partnerId },
            signal
          );
          return {
            data: data as PartnerWindow,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),

    getPartnerTestPage: builder.query<PartnerWindow, PartnerTestPageRequest>({
      queryFn: async ({ partnerId }, { signal }) => {
        try {
          const { data } = await CmsContainersApi.listCustomerContainers(
            getBaseEndpoint(),
            { page: 0, size: 100000, partnerId },
            signal
          );
          return {
            data: data as PartnerWindow,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),

    getPartnersNameMap: builder.query<PartnerNameMap, void>({
      queryFn: async (_, { signal }) => {
        try {
          const { data } = await CustomersApi.listPartnerFirstSymbols(getBaseEndpoint(), signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
  }),
});

export const {
  useGetPartnerTradeOfferListQuery,
  useGetPartnerTradeOfferCategoriesUsedQuery,
  useGetPartnerAspOfferListQuery,
  useGetPartnerAspOfferCategoriesUsedQuery,
  useGetPartnerCorpOfferCategoriesUsedQuery,
  useGetPartnerCorpOfferListQuery,
  useGetPartnerProductOfferListQuery,
  useGetPartnerBookingOfferListQuery,
  useGetPartnerBookingOfferCategoriesUsedQuery,
  useGetPartnerDeskQuery,
  useGetPartnerDesksQuery,
  useGetPartnerPageQuery,
  useGetPartnerDesksCollectionQuery,
  useGetPartnerTestPageQuery,
  useGetPartnersNameMapQuery,
} = partnerApi;
