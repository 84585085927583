import axios from 'axios';
import { useLazyGetUserAgreementsQuery, useSignUserAgreementsMutation } from 'data/api/agreements';
import { ENetworkErrorCode } from 'data/network/types';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { FCC, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import ContentLoader from '../../../components/common/loader';
import SignAgreementsDialog from '../components/signAgreementsDialog';
import { BackdropWrapper } from './controls';

const LegalAgreementContainer: FCC = ({ children }) => {
  const { logOut } = useAuth();
  const { tokenParsed } = useAuthToken();

  const dispatch = useDispatch();

  const [triggerFetchUserAgreements, agreementsToSignResult] = useLazyGetUserAgreementsQuery();

  const { data: agreementsToSign, isFetching: isAgreementsListFetching } = agreementsToSignResult;

  const [triggerSignAgreements, { isLoading: isAgreementsListSigning }] = useSignUserAgreementsMutation();

  const open = !!agreementsToSign?.length;

  useLayoutEffect(() => {
    const interceptor = axios.interceptors.response.use(undefined, error => {
      if (error?.response?.status === 403 && error?.response?.data?.code === ENetworkErrorCode.MissingUserAgreement) {
        triggerFetchUserAgreements({});
      }

      return Promise.reject(error);
    });

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [tokenParsed, dispatch, triggerFetchUserAgreements]);

  const onAccept = async () => {
    if (!tokenParsed || !agreementsToSign) {
      return;
    }

    const docLinks = agreementsToSign.map(a => ({
      id: a.doc.id,
    }));

    try {
      await triggerSignAgreements({ docs: docLinks });
    } catch {
      console.error('User agreements sign is failed');
    }
  };

  const onReject = () => {
    logOut();
  };

  return (
    <>
      {children}
      {isAgreementsListFetching && (
        <BackdropWrapper>
          <ContentLoader />
        </BackdropWrapper>
      )}
      {open && (
        <SignAgreementsDialog
          agreements={agreementsToSign ?? []}
          open
          isAccepting={isAgreementsListSigning}
          onAccept={onAccept}
          onReject={onReject}
        />
      )}
    </>
  );
};

export default LegalAgreementContainer;
