/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GenMainOfferUserEvent, GenMainUserEventType } from './data-contracts';
import { ContentType, createRequest } from './http-client';

export const UserEventsApi = {
  /**
   * No description
   * * @tags UserEvents
   * @name ListUserEventTypes
   * @summary Список типов событий, инициированных пользователем
   * @request GET:/user-event-types
   * @secure
   */
  listUserEventTypes: (baseURL: string, signal?: AbortSignal) =>
    createRequest<GenMainUserEventType[], void>({
      baseURL,
      path: `/user-event-types`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags UserEvents
   * @name GetUserEventType
   * @summary Получение типа события, инициированного пользователем, по коду
   * @request GET:/user-event-types/{userEventTypeCode}
   * @secure
   */
  getUserEventType: (baseURL: string, userEventTypeCode: string, signal?: AbortSignal) =>
    createRequest<GenMainUserEventType, void>({
      baseURL,
      path: `/user-event-types/${userEventTypeCode}`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags UserEvents
   * @name PostOfferUserEvents
   * @summary Создание записей о событиях, связанных с предложением и пользователем
   * @request POST:/offers/events
   * @secure
   */
  postOfferUserEvents: (baseURL: string, data: GenMainOfferUserEvent[], signal?: AbortSignal) =>
    createRequest<void, GenMainOfferUserEvent[]>({
      baseURL,
      path: `/offers/events`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      signal,
    }),
};
