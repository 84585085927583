import { Typography, useMediaQuery } from '@mui/material';
import AddressCitySingleSelector from 'components/common/address/singleSelector/city';
import { Address } from 'domain/model';
import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import useUserLocalSettings from 'hooks/useUserLocalSettings';
import { FC, useState } from 'react';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { MPGrid } from 'theme/ui-kit/grid';
import { AddressHelper } from 'utils/address';
import { ChangeCityEventProps } from './types';

export const ChangeCityEventContainer: FC<ChangeCityEventProps> = ({ onNext }) => {
  const { setCity } = useUserLocalSettings();

  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const [city, setLocalCity] = useState<Nullable<Address>>(null);

  const onSaveValue = (value: Address) => {
    setCity(value.id, new AddressHelper(value).getLocalitySimpleName());
  };

  const onChangeLocalCity = (address: Nullable<Address>) => {
    if (!address) {
      setLocalCity(null);
    } else {
      setLocalCity(address);

      if (isSmDown) {
        onSaveValue(address);
        onNext();
      }
    }
  };

  const onApply = () => {
    if (city) {
      onSaveValue(city);
      onNext();
    }
  };

  const getOptionLabel = (address: Address) => new AddressHelper(address).getLocalitySimpleName();

  return (
    <DialogueEventContentBuilderLayout
      title='Выбор города'
      actions={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            disabled={!city}
            color='brand'
            size='large'
            onClick={onApply}
          >
            Продолжить
          </MPButton>
          <MPButton
            color='brand'
            variant='text'
            fullWidth
            size='large'
            onClick={onNext}
          >
            Отмена
          </MPButton>
        </ConfirmGroupButtons>
      }
    >
      <MPGrid
        container
        spacing={2}
      >
        <MPGrid size={12}>
          <Typography variant='body2'>Укажите, где вы находитесь для правильного отображения предложений</Typography>
        </MPGrid>
        <MPGrid size={12}>
          <AddressCitySingleSelector
            value={city}
            label='Город'
            getOptionLabel={getOptionLabel}
            onChange={onChangeLocalCity}
          />
        </MPGrid>
      </MPGrid>
    </DialogueEventContentBuilderLayout>
  );
};
