import { Typography } from '@mui/material';
import { ECorpOfferPromotionType, EDateFormat } from 'domain/model';
import moment from 'moment-timezone';
import PartnerLink from 'presentation/components/common/partnerLink';
import Splitter from 'presentation/components/common/splitter';
import ActivationListItemExpireDate from 'presentation/features/activation/components/sidebar/expiryDate/title';
import ActivationListItemLabel from 'presentation/features/activation/components/sidebar/label/title';
import { OfferPromotionDownload } from 'presentation/features/offer/components/promotionDownload';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { getLeftSeconds } from 'presentationUtils/date';
import { DownloadablePromotionTypes } from '../../../types';
import { SidebarWrapper } from '../controls';

type ActivationListItemFileSidebarProps = {
  readonly promotionType: DownloadablePromotionTypes;
  readonly expireDate: Nullable<string>;
  readonly appointmentDate: Nullable<string>;
  readonly codeTypeName: string;
  readonly partnerLink?: Nullable<string>;
  readonly downloadLabel: string;
  readonly isDownloading: boolean;
  readonly onOpenHelpDialog: () => void;
  readonly onPartnerLinkClick?: () => void;
  readonly onDownload: () => void;
};

const ActivationListItemFileSidebar = (props: ActivationListItemFileSidebarProps) => {
  const {
    expireDate,
    promotionType,
    appointmentDate,
    codeTypeName,
    partnerLink,
    downloadLabel,
    onPartnerLinkClick,
    isDownloading,
    onDownload,
  } = props;

  const expireDateLocal = moment(expireDate);
  const activationExpired = expireDate && getLeftSeconds(expireDateLocal) <= 0;

  const isCertificate =
    promotionType === ECorpOfferPromotionType.CorpCertificate ||
    promotionType === ECorpOfferPromotionType.ExternalCorpCertificate;

  return (
    <SidebarWrapper>
      <MPGrid
        container
        spacing={1}
        direction='column'
      >
        <MPGrid>
          <ActivationListItemLabel
            codeTypeName={codeTypeName}
            appointmentDate={appointmentDate}
          />
          {expireDate && <ActivationListItemExpireDate expireDate={expireDate} />}
        </MPGrid>
        {isCertificate && activationExpired && (
          <MPGrid>
            <Typography color='error'>
              Срок действия сертификата истек {moment(expireDateLocal).format(EDateFormat.Human)} года.
            </Typography>
          </MPGrid>
        )}
        {!(isCertificate && activationExpired) && (
          <MPGrid>
            <OfferPromotionDownload
              label={downloadLabel}
              onClick={onDownload}
              isFetching={isDownloading}
            />
          </MPGrid>
        )}
        {!(isCertificate && activationExpired) && partnerLink && onPartnerLinkClick && (
          <MPGrid>
            <Splitter
              variant='horizontal'
              size={1}
            />
            <PartnerLink
              link={partnerLink}
              onClick={onPartnerLinkClick}
            />
          </MPGrid>
        )}
      </MPGrid>
    </SidebarWrapper>
  );
};

export default ActivationListItemFileSidebar;
