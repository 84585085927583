import { getDataCmsCollectionRoute } from '../../../data/entry';
import { CmsContainerShowAllLink } from '../../components/showAllLink';
import { CmsOfferCollectionAdapterProps } from '../types';

/**
 * адаптер который добавляет в хедер кнопку "Посмотреть всё"
 */
export const CmsCollectionShowAllAdapter = (props: Pick<CmsOfferCollectionAdapterProps, 'collection'>) => {
  const {
    collection: { id },
  } = props;

  const link = getDataCmsCollectionRoute({ id });

  return <CmsContainerShowAllLink to={link} />;
};
