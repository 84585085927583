import { EDateFormat, FileLink } from 'domain/model';
import moment from 'moment-timezone';
import ContentLoader from 'presentation/components/common/loader';
import Splitter from 'presentation/components/common/splitter';
import { UserEditCommonAttributes } from 'presentation/features/user/utils';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPFormInput } from 'presentation/theme/ui-kit/input';
import { MPSingleDatePicker } from 'presentation/theme/ui-kit/picker';
import { ValidationResult } from 'presentation/utils/validation';
import React, { useState } from 'react';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import UserAvatarEditorContainer from '../../avatarEditor/container';
import { UserGenderSelector } from '../genderSelector';
import { UserPhotoSelector } from '../photoSelector';
import { UserProfileEditAttributeCommonProps } from './types';

type UserProfileEditAttributeCommonInfoProps = UserProfileEditAttributeCommonProps & {
  readonly user: UserEditCommonAttributes;
  readonly validation?: Nullable<ValidationResult<UserEditCommonAttributes>>;
  readonly isCorpUser: boolean;
  readonly onChange: <A extends keyof UserEditCommonAttributes>(
    attribute: A,
    value: UserEditCommonAttributes[A]
  ) => void;
};

export const UserProfileEditAttributeCommonInfo = (props: UserProfileEditAttributeCommonInfoProps) => {
  const { label, isFetching, user, validation, onChange, onSave, onClose, isCorpUser } = props;

  const [newPhoto, setNewPhoto] = useState<Nullable<File>>(null);

  const onChangePhoto = (photo: Nullable<FileLink>) => {
    onChange('photo', photo);
    setNewPhoto(null);
  };

  return (
    <>
      <MPConfirmDialog
        open
        title={label}
        maxWidth='xs'
        fullScreenBreakpoint='sm'
        scroll='body'
        buttons={
          <ConfirmGroupButtons>
            <MPButton
              fullWidth
              color='brand'
              size='large'
              disabled={isFetching}
              onClick={onSave}
            >
              Сохранить
              {isFetching && <ContentLoader />}
            </MPButton>
            <MPButton
              color='brand'
              size='large'
              fullWidth
              variant='text'
              disabled={isFetching}
              onClick={onClose}
            >
              Отмена
            </MPButton>
          </ConfirmGroupButtons>
        }
        onClose={onClose}
      >
        <MPGrid
          container
          spacing={2}
          direction='column'
        >
          <MPGrid>
            <UserPhotoSelector
              src={user.photo?.path}
              onChange={setNewPhoto}
              onClear={() => onChangePhoto(null)}
            />
            <Splitter size={2} />
          </MPGrid>
          <MPGrid>
            <MPFormInput
              label='Фамилия'
              value={user.lastName ?? ''}
              error={!!validation?.lastName?.hasError}
              helperText={validation?.lastName?.message}
              disabled={isFetching || isCorpUser}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange('lastName', event.target.value)}
            />
          </MPGrid>
          <MPGrid>
            <MPFormInput
              label='Имя'
              value={user.firstName ?? ''}
              error={!!validation?.firstName?.hasError}
              helperText={validation?.firstName?.message}
              disabled={isFetching || isCorpUser}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange('firstName', event.target.value)}
            />
          </MPGrid>
          <MPGrid>
            <MPFormInput
              label='Отчество'
              value={user.middleName ?? ''}
              error={!!validation?.middleName?.hasError}
              helperText={validation?.middleName?.message}
              disabled={isFetching || isCorpUser}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange('middleName', event.target.value)}
            />
          </MPGrid>
          <MPGrid>
            <MPGrid
              container
              spacing={2}
              wrap='nowrap'
            >
              <MPGrid size='grow'>
                <MPSingleDatePicker
                  label='Дата рождения'
                  value={(user.birthDate && moment(user.birthDate).toDate()) || null}
                  disabled={isFetching || isCorpUser}
                  error={!!validation?.birthDate?.hasError}
                  helperText={validation?.birthDate?.message}
                  calendarPosition={'top'}
                  fixMainPosition
                  onChange={value => {
                    if (isCorpUser) {
                      return;
                    }

                    onChange('birthDate', value ? moment(value).format(EDateFormat.Server) : null);
                  }}
                />
              </MPGrid>
              <MPGrid>
                <UserGenderSelector
                  value={user.gender ?? null}
                  disabled={isFetching || isCorpUser}
                  onChange={value => onChange('gender', value)}
                  error={!!validation?.gender?.hasError}
                  helperText={validation?.gender?.message}
                />
              </MPGrid>
            </MPGrid>
          </MPGrid>
        </MPGrid>
      </MPConfirmDialog>

      {newPhoto && (
        <UserAvatarEditorContainer
          source={newPhoto}
          onClose={() => setNewPhoto(null)}
          onChange={onChangePhoto}
        />
      )}
    </>
  );
};
