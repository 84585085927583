import { AxiosStatic, CancelToken } from 'axios';
import HttpClient from 'data/network/http';
import { AppConfigurator } from 'system/appConfigurator';

export const getBaseEndpoint = () => AppConfigurator.getInstance().getApiBase();

export const getWsServicesEndpoint = () => AppConfigurator.getInstance().getApiWsUrl();

export const getFilesEndpoint = () => AppConfigurator.getInstance().getApiFilesPath();

export const getBonusesEndpoint = (): string => AppConfigurator.getInstance().getApiBonusesPath();

export const getAddressEndpoint = (): string => AppConfigurator.getInstance().getApiAddressPath();

export const getBenefitTransactionEndpoint = (): string => AppConfigurator.getInstance().getApiBenefitTransactionPath();

export const getFileDownloadEndpoint = (props: { id: string; private?: boolean }) => {
  const token = HttpClient.getInstance().getAuthService()?.token;
  const params = new URLSearchParams();

  params.append('path', props.id);
  params.append('cache-control', 'private, max-age=2592000, must-revalidate');
  if (props.private && token) params.append('access_token', token);

  return `${getFilesEndpoint()}/files?${params}`;
};

export const getCertificateDownloadEndpoint = (activationId: UUID) =>
  `${getBaseEndpoint()}/customers/current/activations/${activationId}/cert`;

export const createCancelToken = (axios: AxiosStatic, signal: AbortSignal): CancelToken => {
  const source = axios.CancelToken.source();

  signal.addEventListener('abort', () => {
    source.cancel();
  });

  return source.token;
};
