import { Tab, useMediaQuery } from '@mui/material';
import { BookingOffer } from 'domain/model';
import { TabsWrapper } from 'presentation/components/common/wrappers/tabs';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FC } from 'react';
import PartnerDeskDetailsAboutLayout from '../../../../partnerDesk/details/tabs/aboutCompany/layout';
import OfferDetailsTabContacts from '../../../components/detailsTabs/contacts';
import { OfferTabs } from '../../../components/tabs/controls';
import BookingOfferServicesTabsAdapter from '../adapters/services';
import { EBookingOfferDetailsTab } from '../types';

type BookingOfferTabsProps = {
  readonly id: UUID;
  readonly tab: EBookingOfferDetailsTab;
  readonly onChange: (event: React.SyntheticEvent, newTab: EBookingOfferDetailsTab) => void;
  readonly bookingOffer: BookingOffer;
};

const BookingOfferTabs: FC<BookingOfferTabsProps> = ({ onChange, tab, id, bookingOffer }) => {
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <MPGrid
      container
      spacing={isMdUp ? 3 : 2}
    >
      <MPGrid size='grow'>
        <TabsWrapper>
          <OfferTabs
            value={tab}
            textColor='secondary'
            variant='scrollable'
            onChange={onChange}
            scrollButtons={false}
          >
            <Tab
              label='Услуги'
              value={EBookingOfferDetailsTab.Services}
            />
            {bookingOffer.description && (
              <Tab
                label='Описание'
                value={EBookingOfferDetailsTab.Description}
              />
            )}
            {bookingOffer.contacts && (
              <Tab
                label='Контакты'
                value={EBookingOfferDetailsTab.Contacts}
              />
            )}
          </OfferTabs>
        </TabsWrapper>
      </MPGrid>
      <MPGrid size={12}>
        {tab === EBookingOfferDetailsTab.Services && <BookingOfferServicesTabsAdapter id={id} />}
        {tab === EBookingOfferDetailsTab.Description && bookingOffer.description && (
          <PartnerDeskDetailsAboutLayout>{bookingOffer.description}</PartnerDeskDetailsAboutLayout>
        )}
        {tab === EBookingOfferDetailsTab.Contacts && bookingOffer.contacts && (
          <OfferDetailsTabContacts
            contacts={bookingOffer.contacts}
            descriptionTitle='Как добраться'
            description={bookingOffer.routeDescription}
          />
        )}
      </MPGrid>
    </MPGrid>
  );
};

export default BookingOfferTabs;
