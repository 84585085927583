import axios, { AxiosResponse, CancelToken } from 'axios';
import { Address, AddressLink, EAddressLevel } from 'domain/model';
import { createCancelToken, getAddressEndpoint } from './utils';

export type ApiCancellable = {
  readonly signal?: AbortSignal;
};

const countDefault = 20 as const;

type AddressQueryProps = ApiCancellable & {
  readonly query: string;
  readonly count?: number;
  readonly level?: EAddressLevel;
  readonly fromLevel?: EAddressLevel;
  readonly toLevel?: EAddressLevel;
  readonly locations?: AddressLink[];
  readonly cancelToken?: CancelToken;
};

type AddressByIpQueryProps = ApiCancellable & {
  readonly cancelToken?: CancelToken;
};

type AddressApiType = {
  readonly addressQuery: (props: AddressQueryProps) => Promise<AxiosResponse<Address[]>>;
  readonly addressByIpQuery: (props: AddressByIpQueryProps) => Promise<AxiosResponse<Address>>;
};

const addressApi: AddressApiType = {
  addressQuery: ({ query, count = countDefault, level, fromLevel, toLevel, locations, signal, cancelToken }) => {
    const params = new URLSearchParams();
    if (query) {
      params.append('q', query);
    }
    params.append('maxCount', count.toString(10));
    locations?.forEach(l => params.append('locations', JSON.stringify(l)));

    if (level) {
      if (level === EAddressLevel.Value4) {
        params.append('fromLevel', EAddressLevel.Value5.toString(10));
        params.append('toLevel', EAddressLevel.Value4.toString(10));
      } else {
        params.append('fromLevel', level.toString(10));
        params.append('toLevel', level.toString(10));
      }
    } else if (fromLevel || toLevel) {
      if (fromLevel) {
        params.append('fromLevel', fromLevel.toString(10));
      }
      if (toLevel) {
        params.append('toLevel', toLevel.toString(10));
      }
    }

    return axios.get(`${getAddressEndpoint()}/addresses/suggest`, {
      params,
      cancelToken: cancelToken ?? (signal && createCancelToken(axios, signal)),
    });
  },
  addressByIpQuery: ({ signal, cancelToken }) => {
    return axios.get(`${getAddressEndpoint()}/iplocate`, {
      cancelToken: cancelToken ?? (signal && createCancelToken(axios, signal)),
    });
  },
};

export default addressApi;
