import { Link, Typography } from '@mui/material';
import { CartItem } from 'domain/model';
import AppImage from 'presentation/components/common/images/common';
import { WarnMessage } from 'presentation/components/common/messages';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { getProductDisplayName } from '../../../offer/product/utils';
import CartProviderContainer from '../../cartProvider/container';
import CartRemoveButton from '../cartRemoveButton';
import CartCounter from '../counter';
import { ContentGrid, ContentGridArea, ECartItemGridAreas, PreviewWrapper, Wrapper } from './controls';
import CartListItemFinanceInfo from './financeInfo';

type CartListItemProps = {
  readonly data: CartItem;
  readonly enabled: boolean;
  readonly onOpenProductDetails: () => void;
  readonly onAddOrRemoveFavorite: () => void;
  readonly isFavoriteFetching: (id: UUID) => boolean;
};

const CartListItem = (props: CartListItemProps) => {
  const { data, enabled, onOpenProductDetails } = props;

  const { offer } = data;

  if (!offer) {
    return null;
  }

  const { images, price, originalPrice, salePercent, stock } = offer;
  const displayName = getProductDisplayName(offer);
  const preview = images?.[0] ?? null;

  return (
    <Wrapper>
      <CartProviderContainer product={offer}>
        {({ cartItem, isItemFetching, deleteItem, decrement, increment, updateItem, isOutOfQuantity }) => (
          <ContentGrid>
            <ContentGridArea area={ECartItemGridAreas.Preview}>
              {preview && (
                <Link
                  color={enabled ? 'textPrimary' : 'textSecondary'}
                  underline='hover'
                  onClick={onOpenProductDetails}
                >
                  <PreviewWrapper enabled={enabled}>
                    <AppImage src={preview} />
                  </PreviewWrapper>
                </Link>
              )}
            </ContentGridArea>
            <ContentGridArea area={ECartItemGridAreas.Name}>
              <Link
                color={enabled ? 'textPrimary' : 'textSecondary'}
                underline='hover'
                onClick={onOpenProductDetails}
              >
                {displayName}
              </Link>
            </ContentGridArea>

            <ContentGridArea area={ECartItemGridAreas.Finance}>
              {enabled && (
                <CartListItemFinanceInfo
                  price={price}
                  originalPrice={originalPrice}
                  salePercent={salePercent}
                />
              )}
              {!enabled && <Typography>Нет в наличии</Typography>}
            </ContentGridArea>

            <ContentGridArea
              area={ECartItemGridAreas.Counter}
              flexDirection='column'
            >
              {enabled && (
                <MPGrid
                  container
                  spacing={0.5}
                >
                  <MPGrid>
                    <CartCounter
                      count={cartItem?.quantity ?? 0}
                      isEmpty={(cartItem?.quantity ?? 0) === 0}
                      loading={isItemFetching}
                      isWarn={isOutOfQuantity}
                      disabled={isItemFetching}
                      max={stock ?? 0}
                      size='small'
                      onDecrement={decrement}
                      onIncrement={increment}
                      onChange={count => updateItem(count)}
                    />
                  </MPGrid>
                  <MPGrid>
                    <WarnMessage
                      show={isOutOfQuantity}
                      text={`Есть: ${cartItem?.offer?.stock}`}
                    />
                  </MPGrid>
                </MPGrid>
              )}
            </ContentGridArea>
            <ContentGridArea area={ECartItemGridAreas.Remove}>
              <CartRemoveButton onClick={deleteItem} />
            </ContentGridArea>
            {/*<ContentGridArea area={ECartItemGridAreas.Favorite}>
              <ButtonLink
                startIcon={
                  <AppSvgIcon
                    color={favorite ? 'primary' : 'secondary'}
                    icon={IconFavoriteFilled}
                  />
                }
                onClick={onAddOrRemoveFavorite}
                disabled={isFavoriteProcessing}
              >
                <Typography color='textPrimary'>{favorite ? 'В избранном' : 'В избранное'}</Typography>
                {isFavoriteProcessing && <ContentLoader size={20} />}
              </ButtonLink>
            </ContentGridArea>*/}
          </ContentGrid>
        )}
      </CartProviderContainer>
    </Wrapper>
  );
};

export default CartListItem;
