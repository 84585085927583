import { Fade } from '@mui/material';
import { PartitionIcon } from 'presentation/components/common/partitionIcon';
import { EAppCategories } from 'presentation/types';
import { ReactNode } from 'react';
import OffersCategoryHeader from '../../../components/categoryHeader';
import OffersCommonHeader from '../../../components/commonHeader';
import OffersSearchHeader from '../../../components/searchHeader';
import { useContextHandlers } from '../hooks/useContextHandlers';
import useCorpOffersHeader from '../hooks/useHeader';

type CorpOffersHeaderAdapterProps = {
  readonly header?: ReactNode;
};

export const CorpOffersHeaderAdapter = (props: CorpOffersHeaderAdapterProps) => {
  const { header } = props;
  const handlers = useContextHandlers();

  const {
    isNewSearchFetching,
    isSearchHeaderVisible,
    isCommonHeaderVisible,
    isCategoryHeaderVisible,
    isAnyHeaderVisible,
    selectedCategory,
    categoriesOut,
    initialTotalCount,
  } = useCorpOffersHeader();

  if (isNewSearchFetching) {
    return null;
  }

  if (header) {
    return header;
  }

  if (!isAnyHeaderVisible) {
    return null;
  }

  return (
    <Fade in>
      <div>
        {isSearchHeaderVisible && (
          <OffersSearchHeader
            category={selectedCategory}
            count={initialTotalCount}
            categories={categoriesOut}
            onChangeCategory={handlers.onChangeCategory}
          />
        )}
        {isCategoryHeaderVisible && selectedCategory && (
          <OffersCategoryHeader
            category={selectedCategory}
            onChangeCategory={handlers.onReturnToTopCategory}
          />
        )}
        {isCommonHeaderVisible && (
          <OffersCommonHeader
            icon={
              <PartitionIcon
                fontSize='large'
                type={EAppCategories.CorpOffers}
              />
            }
          >
            {EAppCategories.CorpOffers}
          </OffersCommonHeader>
        )}
      </div>
    </Fade>
  );
};
