import { AxiosResponse } from 'axios';
import { cacheKeys } from 'data/api';
import { cartApi, useGetCartQuery } from 'data/api/cart';
import { useCreateUserOrdersMutation } from 'data/api/user';
import { businessErrorCode } from 'data/network/constants';
import ErrorHandler from 'data/network/errorHandler';
import { ServerErrorResponse } from 'data/network/types';
import { CartItem, EOrderCreateError } from 'domain/model';
import ContentLoader from 'presentation/components/common/loader';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import Notifier from 'system/notifier';
import useWatchApiMutations from '../../../hooks/useWatchApiMutations';
import { toRubCurrency } from '../../../utils/currency';
import CartProductsNotFoundDialog from '../components/dialogs/productsNotFound';
import CartOrderCreateHelp from '../components/orderCreateHelp';
import { getLastOrderRoute } from '../entry';
import { isCartItemStockOutOfQuantity } from '../utils';
import CartSidebarLayout from './layout';

type CartSidebarContainerProps = {
  readonly items: CartItem[];
};

type Data = {
  readonly count: number;
  readonly cost: number;
  readonly discount: number;
};

const Layout = CartSidebarLayout;

const CartSidebarContainer = ({ items }: CartSidebarContainerProps) => {
  const history = useHistory();

  const mutationsLoading = useWatchApiMutations({
    deps: [items],
    api: cartApi,
    endpoints: ['getCart', 'getCartItem', 'deleteCartItem', 'updateCartItem', 'addItemToCart'],
  });

  const [productsNotFoundDialogVisible, setProductsNotFoundDialogVisible] = useState<boolean>(false);

  const { refetch: cartRefetch } = useGetCartQuery();
  const [createOrders, { isLoading }] = useCreateUserOrdersMutation(cacheKeys.order);

  const data = useMemo<Data>(() => {
    return items.reduce<Data>(
      (prev, next) => {
        const cost = next.offer && next.offer.price !== null ? Math.abs(next.offer.price * (next.quantity ?? 0)) : 0;
        const discount =
          next.offer && next.offer.price !== null
            ? Math.abs(next.offer.price - (next.offer.originalPrice ?? 0)) * (next.quantity ?? 0)
            : 0;
        return {
          count: prev.count + (next.quantity ?? 0),
          cost: prev.cost + cost,
          discount: prev.discount + discount,
        };
      },
      {
        count: 0,
        cost: 0,
        discount: 0,
      }
    );
  }, [items]);

  const showProductsNotFoundDialogVisible = useCallback(() => setProductsNotFoundDialogVisible(true), []);

  const hideProductsNotFoundDialogVisible = useCallback(() => setProductsNotFoundDialogVisible(false), []);

  const parseOrderCreateError = useCallback(
    (error: ServerErrorResponse) => {
      const type = error.code as EOrderCreateError;
      switch (type) {
        case EOrderCreateError.ProductNotFound:
          ErrorHandler.handleBusinessError(error);
          cartRefetch();
          showProductsNotFoundDialogVisible();
          break;
        default:
          Notifier.getInstance().addError('При создании заказов возникла неизвестная ошибка');
      }
    },
    [cartRefetch, showProductsNotFoundDialogVisible]
  );

  const parseOrderCreateErrorResponse = useCallback(
    (response: AxiosResponse<ServerErrorResponse>) => {
      switch (response.status) {
        case businessErrorCode:
          if (!response.data.code) {
            ErrorHandler.handleHttpError(response);
          } else {
            parseOrderCreateError(response.data);
          }
          return;
        default: {
          ErrorHandler.handleHttpError(response);
        }
      }
    },
    [parseOrderCreateError]
  );

  const onOrderCreate = () => {
    createOrders(items)
      .unwrap()
      .then(() => history.push(getLastOrderRoute()))
      .catch((e: AxiosResponse<ServerErrorResponse>) => parseOrderCreateErrorResponse(e));
  };

  const { cost, count, discount } = data;

  const someItemsMoreThanStock = items.some(i => isCartItemStockOutOfQuantity(i));
  const canCreateOrders = !isLoading && count > 0 && !someItemsMoreThanStock;

  return (
    <>
      <Layout
        title={`Товаров: ${count}`}
        discount={discount > 0 && `Скидка: ${toRubCurrency(discount)}`}
        cost={toRubCurrency(cost)}
        createOrdersButton={
          <MPButton
            fullWidth
            disabled={!canCreateOrders || mutationsLoading}
            onClick={onOrderCreate}
          >
            Оформить заказы
            {isLoading && <ContentLoader />}
          </MPButton>
        }
        help={<CartOrderCreateHelp />}
      />
      <CartProductsNotFoundDialog
        open={productsNotFoundDialogVisible}
        onClose={hideProductsNotFoundDialogVisible}
      />
    </>
  );
};

export default CartSidebarContainer;
