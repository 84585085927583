import { Typography, useMediaQuery } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FC, ReactNode } from 'react';

type PartnerDeskDetailsTabToolbarLayoutProps = {
  readonly title: ReactNode;
  readonly sort?: ReactNode;
};

export const PartnerDeskDetailsTabToolbarLayout: FC<PartnerDeskDetailsTabToolbarLayoutProps> = props => {
  const { title, sort } = props;

  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <MPGrid
      container
      alignItems='center'
      justifyContent='space-between'
    >
      {isMdUp && (
        <MPGrid size={{ md: 'grow' }}>
          <Typography
            variant='h1'
            component='h2'
          >
            {title}
          </Typography>
        </MPGrid>
      )}
      {sort && <MPGrid>{sort}</MPGrid>}
    </MPGrid>
  );
};
