import { CloseIcon } from 'presentation/media/icons';
import { MPFab } from 'presentation/theme/ui-kit/button';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { Wrapper } from './controls';

export type DataFilterViewValue = {
  id: Nullable<string>;
  name: Nullable<string>;
};

type DataFilterViewProps = {
  readonly values: DataFilterViewValue[];
  readonly onClear: VoidFunction;
  readonly onDelete: (id: string) => void;
};

const DataFilterView = (props: DataFilterViewProps) => {
  const { values, onClear, onDelete } = props;

  return (
    <Wrapper>
      <MPGrid
        container
        spacing={1}
        alignItems='center'
      >
        <MPGrid>
          <MPFab
            color='black'
            size='micro'
            onClick={onClear}
          >
            <CloseIcon />
          </MPFab>
        </MPGrid>
        {values.map(({ id, name }) => (
          <MPGrid key={id}>
            {id && (
              <MPChip
                label={name}
                color='black'
                variant='filled'
                onDelete={() => onDelete(id)}
              />
            )}
          </MPGrid>
        ))}
      </MPGrid>
    </Wrapper>
  );
};

export default DataFilterView;
