import { Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FCC } from 'react';

type BookingSlotItemProps = {
  readonly title: string;
  readonly width?: number;
};

export const BookingSlotItem: FCC<BookingSlotItemProps> = ({ title, width = 6, children }) => (
  <MPGrid size={{ xs: width }}>
    <Typography
      variant='body2'
      color='secondary'
    >
      {title}
    </Typography>
    <Typography
      mb={1}
      variant='body1'
    >
      {children}
    </Typography>
  </MPGrid>
);
