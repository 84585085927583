import styled from '@emotion/styled';
import { buttonClasses, Typography } from '@mui/material';
import { StyledButtonLink } from 'presentation/components/common/buttons/link/controls';
import { MPGrid } from 'presentation/theme/ui-kit/grid';

const counterWidth = '7.2rem';
const previewWidth = '100%';
const previewHeight = '5.6rem';

type PreviewWrapperProps = {
  readonly enabled: boolean;
};

export enum ECartItemGridAreas {
  Preview = 'preview',
  Name = 'name',
  Remove = 'remove',
  Favorite = 'favorite',
  Counter = 'counter',
  Available = 'available',
  Finance = 'finance',
}

export const Wrapper = styled.div``;

export const PreviewWrapper = styled.div<PreviewWrapperProps>`
  width: ${previewWidth};
  height: ${previewHeight};

  display: flex;
  align-items: flex-start;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  ${p => !p.enabled && `opacity: 0.4;`}
`;

export const Price = styled(Typography)`
  line-height: ${p => p.theme.typography.h2.fontSize};
`;

export const OriginalPrice = styled(Typography)`
  text-decoration: line-through;
`;

export const SalePercent = styled(Typography)``;

export const ShortPrice = styled(Typography)`
  margin-left: 3.5rem;
  font-weight: bold;

  ${p => p.theme.breakpoints.down('sm')} {
    margin-left: 0;
  }
`;

export const ShortFinanceBlock = styled.div`
  display: flex;

  ${p => p.theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
  }
`;

type DetailsGridAreaProps = {
  readonly area: ECartItemGridAreas;
};

export const ContentGridArea = styled(MPGrid)<DetailsGridAreaProps>`
  grid-area: ${p => p.area};
`;

export const ContentGrid = styled.div`
  width: 100%;

  display: grid;
  grid-column-gap: ${p => p.theme.spacing()};
  grid-row-gap: ${p => p.theme.spacing()};
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: auto;

  > [area=${ECartItemGridAreas.Counter}] {
    display: flex;
    justify-content: flex-end;

    > * {
      width: ${counterWidth};
    }
  }

  > [area=${ECartItemGridAreas.Finance}] {
    text-align: right;
  }

  grid-template-areas:
    '${ECartItemGridAreas.Preview} ${ECartItemGridAreas.Preview} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Counter} ${ECartItemGridAreas.Counter} ${ECartItemGridAreas.Finance} ${ECartItemGridAreas.Finance} ${ECartItemGridAreas.Finance}'
    '${ECartItemGridAreas.Preview} ${ECartItemGridAreas.Preview} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Available} ${ECartItemGridAreas.Available} ${ECartItemGridAreas.Finance} ${ECartItemGridAreas.Finance} ${ECartItemGridAreas.Finance}'
    '${ECartItemGridAreas.Preview} ${ECartItemGridAreas.Preview} ${ECartItemGridAreas.Remove} ${ECartItemGridAreas.Remove} ${ECartItemGridAreas.Favorite} ${ECartItemGridAreas.Favorite} . . . ${ECartItemGridAreas.Finance} ${ECartItemGridAreas.Finance} ${ECartItemGridAreas.Finance}';

  ${p => p.theme.breakpoints.down('lg')} {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      '${ECartItemGridAreas.Preview} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Finance} ${ECartItemGridAreas.Finance}'
      '${ECartItemGridAreas.Preview} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Finance} ${ECartItemGridAreas.Finance}'
      '${ECartItemGridAreas.Preview} ${ECartItemGridAreas.Remove} ${ECartItemGridAreas.Favorite} ${ECartItemGridAreas.Favorite} ${ECartItemGridAreas.Finance} ${ECartItemGridAreas.Finance}'
      '. . . . ${ECartItemGridAreas.Counter} ${ECartItemGridAreas.Counter}';
  }

  ${p => p.theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: ${p => p.theme.spacing(2)};
    grid-row-gap: ${p => p.theme.spacing()};
    grid-template-areas:
      '${ECartItemGridAreas.Preview} ${ECartItemGridAreas.Preview} ${ECartItemGridAreas.Finance} ${ECartItemGridAreas.Finance} ${ECartItemGridAreas.Finance} ${ECartItemGridAreas.Finance}'
      '${ECartItemGridAreas.Preview} ${ECartItemGridAreas.Preview} ${ECartItemGridAreas.Finance} ${ECartItemGridAreas.Finance} ${ECartItemGridAreas.Finance} ${ECartItemGridAreas.Finance}'
      '${ECartItemGridAreas.Preview} ${ECartItemGridAreas.Preview} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Name} ${ECartItemGridAreas.Name}'
      '. . ${ECartItemGridAreas.Counter} ${ECartItemGridAreas.Counter} ${ECartItemGridAreas.Counter} .'
      '. . ${ECartItemGridAreas.Remove} ${ECartItemGridAreas.Favorite} ${ECartItemGridAreas.Favorite} .';

    > [area=${ECartItemGridAreas.Finance}],
        > [area=${ECartItemGridAreas.Counter}] {
      text-align: left;
      justify-content: flex-start;
    }

    ${StyledButtonLink} {
      .${buttonClasses.startIcon} {
        display: none;
      }

      > * {
        color: ${p => p.theme.palette.primary.main};
      }
    }
  }
`;
