import styled from '@emotion/styled';
import { css } from '@mui/material';
import { bgGetPoints } from 'media/images';
import { MPButton } from 'theme/ui-kit/button';

export const EarnPointsButton = styled(MPButton)(
  ({ theme }) => css`
    background-image: url(${bgGetPoints}), linear-gradient(94.7deg, #ff4040 17.36%, #ffa800 100%);
  `
);
