import { Typography } from '@mui/material';
import Splitter from 'components/common/splitter';
import { SocialPackageApplicationForm } from 'domain/model';
import moment from 'moment-timezone';
import { OnChangeObjectAttribute } from 'presentation/types';
import { ValidationResult } from 'presentationUtils/validation';
import { FC, useMemo } from 'react';
import { MPGrid } from 'theme/ui-kit/grid';
import { MPSelect } from 'theme/ui-kit/select';
import { Checkbox, Select } from './controls';

type CspApplicationFormProps = {
  readonly form: SocialPackageApplicationForm;
  readonly validation: Nullable<ValidationResult<SocialPackageApplicationForm>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<SocialPackageApplicationForm>;
};

type Period = {
  key: string;
  label: string;
  month: number;
  year: number;
};

const familyMembersCounts = [1, 2, 3, 4];

const formatPeriodKey = (month: number, year: number) => `${year}-${month}`;

const formatPeriodLabel = (monthName: string, year: number) =>
  `${monthName.charAt(0).toUpperCase() + monthName.slice(1)} ${year}`;

export const CspApplicationForm: FC<CspApplicationFormProps> = ({ form, validation, onChangeAttribute }) => {
  const periods: Period[] = useMemo(
    () =>
      moment
        .months()
        .map((month, index) => ({
          key: formatPeriodKey(index + 1, moment().year()),
          label: formatPeriodLabel(month, moment().year()),
          month: index + 1,
          year: moment().year(),
        }))
        .slice(moment().month()), //месяцы с текущего и до конца года
    []
  );

  const periodValue = form.month && form.year ? formatPeriodKey(form.month, form.year) : null;

  const onChangePeriod = (periodKey: string) => {
    const period = periods.find(p => p.key === periodKey);
    if (period) {
      onChangeAttribute('month', period.month);
      onChangeAttribute('year', period.year);
    }
  };

  return (
    <MPGrid
      container
      spacing={4}
      direction='column'
    >
      <MPGrid>
        <Select
          select
          fullWidth
          value={periodValue ?? ''}
          label='Выбрать месяц заезда'
          error={validation?.month?.hasError || validation?.year?.hasError}
          helperText={validation?.month?.message || validation?.year?.message}
          onChange={event => onChangePeriod(event.target.value)}
        >
          {periods.map(item => (
            <MPSelect.Item
              value={item.key}
              key={item.key}
            >
              <Typography>{item.label}</Typography>
            </MPSelect.Item>
          ))}
        </Select>
      </MPGrid>
      <MPGrid>
        <Checkbox
          size='small'
          color='brand'
          checked={form.withFamilyMembers}
          label='C членами семьи'
          onChange={(event, value) => onChangeAttribute('withFamilyMembers', value)}
        />
        {form.withFamilyMembers && (
          <>
            <Splitter size={2} />
            <Select
              select
              fullWidth
              value={form.familyMembersCount ?? ''}
              label='Количество членов семьи'
              error={validation?.familyMembersCount?.hasError}
              helperText={validation?.familyMembersCount?.message}
              onChange={event => onChangeAttribute('familyMembersCount', Number(event.target.value))}
            >
              {familyMembersCounts.map((item, index) => (
                <MPSelect.Item
                  value={item}
                  key={index}
                >
                  <Typography>{item}</Typography>
                </MPSelect.Item>
              ))}
            </Select>
          </>
        )}
      </MPGrid>
    </MPGrid>
  );
};
