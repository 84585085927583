import { getFileDownloadEndpoint } from 'openApi/utils';
import { userThemeSelector } from 'presentation/features/user/theme/store/selectors';
import { ExternalTheme } from 'presentation/theme';
import { useSelector } from 'react-redux';

const useUserTheme = (): Nullable<ExternalTheme> => {
  const theme = useSelector(userThemeSelector);

  return {
    ...theme,
    logo: theme?.logo ? getFileDownloadEndpoint({ id: theme?.logo }) : undefined,
    favicon: theme?.favicon ? getFileDownloadEndpoint({ id: theme?.favicon }) : undefined,
  };
};

export default useUserTheme;
