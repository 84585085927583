import { EOfferType } from 'domain/model';
import { CmsOfferCategoriesCollection } from '../offerCategories';
import { CmsOfferCategoriesAdapterProps } from '../types';

/**
 * адаптер для ТП по коллекции категорий
 */
export const CmsTradeCategoriesCollectionAdapter = (props: CmsOfferCategoriesAdapterProps) => {
  return (
    <CmsOfferCategoriesCollection
      {...props}
      offerType={EOfferType.TradeOffer}
    />
  );
};
