import { EOfferType } from 'domain/model';
import { CmsOfferCategoriesCollection } from '../offerCategories';
import { CmsOfferCategoriesAdapterProps } from '../types';

/**
 * адаптер для КП по коллекции категорий
 */
export const CmsCorpCategoriesCollectionAdapter = (props: CmsOfferCategoriesAdapterProps) => {
  return (
    <CmsOfferCategoriesCollection
      {...props}
      offerType={EOfferType.CorpOffer}
    />
  );
};
