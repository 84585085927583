import { FormControlLabel, Typography } from '@mui/material';
import { CartItem, PartnerView } from 'domain/model';
import Splitter from 'presentation/components/common/splitter';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { Fragment, useMemo } from 'react';
import CartItemsList from '../cartItemsList';
import CartRemoveButton from '../cartRemoveButton';
import { StyledCheckbox } from './controls';

type CartItemsByPartnersProps = {
  readonly items: CartItem[];
  readonly selected: CartItem[];
  readonly onToggleSelected: (items: CartItem[]) => void;
  readonly isCartItemEnabled: (item: CartItem) => boolean;
  readonly onRemoveItems: (items: CartItem[]) => void;
  readonly onOpenProductDetails: (offer: CartItem['offer']) => void;
  readonly onAddOrRemoveFavorite: (offer: CartItem['offer']) => void;
  readonly isFavoriteFetching: (id: UUID) => boolean;
};

type ItemByPartner = {
  readonly partner: PartnerView;
  readonly items: CartItem[];
};

const CartItemsByPartners = (props: CartItemsByPartnersProps) => {
  const {
    items,
    selected,
    onToggleSelected,
    isCartItemEnabled,
    onRemoveItems,
    onOpenProductDetails,
    onAddOrRemoveFavorite,
    isFavoriteFetching,
  } = props;

  const { x1, x2 } = useGridSpacing();

  const itemsByPartner = useMemo<ItemByPartner[]>(() => {
    return items.reduce<ItemByPartner[]>((prev, next) => {
      if (!next.offer || !next.offer?.partner) {
        return prev;
      }

      const existedPartnerIndex = prev.findIndex(p => p.partner.id === next.offer?.partner?.id);
      if (existedPartnerIndex !== -1) {
        const result = [...prev];
        result[existedPartnerIndex].items.push(next);
        return result;
      } else {
        return [...prev, { partner: next.offer.partner, items: [next] }];
      }
    }, []);
  }, [items]);

  const isAllSelected = (items: CartItem[]) =>
    selected.length > 0 && items.every(item => selected.some(s => s.id === item.id));

  const onToggleAllSelected = (items: CartItem[]) => {
    //если все выделены то переключаем состояние всех, иначе переключаем тех кто не выбран
    if (isAllSelected(items)) {
      onToggleSelected(items);
    } else {
      onToggleSelected(items.filter(item => !selected.some(s => s.id === item.id)));
    }
  };

  return (
    <MPGrid
      container
      spacing={x1}
      alignItems='center'
    >
      {itemsByPartner.map(item => (
        <Fragment key={item.partner.id}>
          <MPGrid size={12}>
            <Typography variant='h4'>{item.partner.name}</Typography>
            <Splitter size={x1} />
          </MPGrid>
          <MPGrid>
            <FormControlLabel
              control={
                <StyledCheckbox
                  color='primary'
                  checked={isAllSelected(item.items)}
                  onChange={() => onToggleAllSelected(item.items)}
                />
              }
              label='Выбрать всё'
            />
          </MPGrid>
          <MPGrid>
            <CartRemoveButton
              onClick={() => onRemoveItems(item.items)}
              count={item.items.length}
            />
          </MPGrid>
          <MPGrid size={12}>
            <CartItemsList
              isFavoriteFetching={isFavoriteFetching}
              items={item.items}
              selected={selected}
              onToggleSelected={onToggleSelected}
              isCartItemEnabled={isCartItemEnabled}
              onOpenProductDetails={onOpenProductDetails}
              onAddOrRemoveFavorite={onAddOrRemoveFavorite}
            />
          </MPGrid>
          <MPGrid size={12}>
            <Splitter size={x2} />
          </MPGrid>
        </Fragment>
      ))}
    </MPGrid>
  );
};

export default CartItemsByPartners;
