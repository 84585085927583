import { Fade, useMediaQuery } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import ScreenNavWrapper from 'presentation/components/common/wrappers/screenNavWrapper';
import { useRef } from 'react';
import useGridSpacing from 'theme/hooks/useGridSpacing';
import useVisibleSectionLinks from '../../features/sectionLinks/hooks/useVisibleSectionLinks';
import OfferListLayoutAsideBehavior from './asideBehavior';
import {
  AnyContentWrapper,
  AsideWrapper,
  BannersWrapper,
  BreadcrumbsWrapper,
  ContentWrapper,
  ControlsWrapper,
  ControlsWrapperDense,
  HeaderWrapper,
} from './controls';
import { OfferListLayoutType } from './types';

const OfferListLayout: OfferListLayoutType = props => {
  const { toolbar, breadcrumb, banners, header, stub, aside, controls, children, loader } = props;

  const { x3 } = useGridSpacing();
  const pageRef = useRef<Nullable<HTMLDivElement>>(null);
  const contentRef = useRef<Nullable<HTMLDivElement>>(null);
  const asideRef = useRef<Nullable<HTMLDivElement>>(null);

  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  useVisibleSectionLinks();

  return (
    <div ref={pageRef}>
      {toolbar && <ScreenNavWrapper>{toolbar}</ScreenNavWrapper>}
      {banners && <BannersWrapper>{banners}</BannersWrapper>}
      <Fade in>
        <DefaultContentWrapper>
          {breadcrumb && <BreadcrumbsWrapper>{breadcrumb}</BreadcrumbsWrapper>}
          {isMdUp
            ? (header || controls) && (
                <>
                  <HeaderWrapper>
                    {header}
                    {controls && <ControlsWrapperDense>{controls}</ControlsWrapperDense>}
                  </HeaderWrapper>
                  <Splitter size={x3} />
                </>
              )
            : header && (
                <>
                  {header}
                  <Splitter size={x3} />
                </>
              )}
          <ContentWrapper>
            <AsideWrapper>
              <div ref={asideRef}>{aside}</div>
            </AsideWrapper>
            {!isMdUp && controls && <ControlsWrapper>{controls}</ControlsWrapper>}
            <AnyContentWrapper ref={contentRef}>
              {children}
              {stub}
            </AnyContentWrapper>
          </ContentWrapper>
          {loader}
        </DefaultContentWrapper>
      </Fade>
      {isMdUp && (
        <OfferListLayoutAsideBehavior
          page={pageRef}
          content={contentRef}
          element={asideRef}
        />
      )}
    </div>
  );
};

export default OfferListLayout;
