import { ProductOfferShort } from 'domain/model';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { toRubCurrency } from '../../../../utils/currency';
import { OriginalPrice, Price, SalePercent } from './controls';

type CartListItemFinanceInfoProps = {
  readonly price: ProductOfferShort['price'];
  readonly originalPrice: ProductOfferShort['originalPrice'];
  readonly salePercent: ProductOfferShort['salePercent'];
};

const CartListItemFinanceInfo = ({ price, originalPrice, salePercent }: CartListItemFinanceInfoProps) => {
  return (
    <MPGrid container>
      <MPGrid size={12}>
        <Price variant='h3'>{toRubCurrency(price)}</Price>
      </MPGrid>
      {originalPrice ? (
        <MPGrid size='auto'>
          <OriginalPrice color='textSecondary'>{toRubCurrency(originalPrice)}</OriginalPrice>
        </MPGrid>
      ) : null}
      <MPGrid size={{ xs: 0.5 }} />
      {salePercent ? (
        <MPGrid size='grow'>
          <SalePercent color='primary.main'>-{salePercent}%</SalePercent>
        </MPGrid>
      ) : null}
    </MPGrid>
  );
};

export default CartListItemFinanceInfo;
