/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GenStorageFileInfo, GenStoragePreloadRequest } from './data-contracts';
import { ContentType, createRequest } from './http-client';

export const StorageApi = {
  /**
   * No description
   * * @tags Storage, client-api
   * @name Preload
   * @summary Сохранить файл во временную область
   * @request POST:/preload
   * @secure
   */
  preload: (
    baseURL: string,
    query: {
      /** Метаданные файла */
      metadata?: Record<string, string> | null;
    },
    data: GenStoragePreloadRequest,
    signal?: AbortSignal,
  ) =>
    createRequest<GenStorageFileInfo, GenStoragePreloadRequest>({
      baseURL,
      path: `/preload`,
      method: 'POST',
      query: query,
      body: data,
      type: ContentType.FormData,
      format: 'json',
      signal,
    }),
};
