import { Typography } from '@mui/material';
import { PartnerContactData } from 'domain/model';
import Splitter from 'presentation/components/common/splitter';
import DangerouslyHtmlWrapper from 'presentation/components/common/wrappers/dangerouslyHtml';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import PartnerDeskContact from '../../../components/contact';
import PartnerDeskDetailsContactsLayout from './layout';

type TradeOfferDetailsTabContactsProps = {
  readonly contacts: readonly PartnerContactData[];
  readonly description?: Nullable<string>;
  readonly descriptionTitle?: string;
};

const Layout = PartnerDeskDetailsContactsLayout;

export const PartnerDeskDetailsTabContacts = ({
  contacts,
  description,
  descriptionTitle,
}: TradeOfferDetailsTabContactsProps) => {
  return (
    <>
      <Layout>
        {contacts.map((contact, index) => (
          <MPGrid
            key={index}
            size={{
              sm: 12,
              lg: 6,
              xl: 4,
            }}
          >
            <PartnerDeskContact contact={contact} />
            <Splitter size={2} />
          </MPGrid>
        ))}
      </Layout>
      {description && (
        <Layout>
          <MPGrid>
            <Typography
              variant='subtitle1'
              mb={1.25}
            >
              {descriptionTitle}
            </Typography>
            <DangerouslyHtmlWrapper>
              <Typography variant='body1'>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </Typography>
            </DangerouslyHtmlWrapper>
          </MPGrid>
        </Layout>
      )}
    </>
  );
};
