import { UsersApi } from '@privilege-frontend/api';
import { getQueryErrorByAxiosError } from 'api';
import { User, UserAgreement, UserAgreementDocLink } from 'domain/model';
import { getBaseEndpoint } from 'openApi/utils';

import { api, EAgreementServicesTag } from './index';

export type SignRequest = {
  readonly docs: UserAgreementDocLink[];
};

export const agreementsApi = api.injectEndpoints({
  endpoints: builder => ({
    checkUserCurrentFetch: builder.query<User, {}>({
      keepUnusedDataFor: 0,
      queryFn: async (_, { signal }) => {
        try {
          const { data } = await UsersApi.onLoginUser(getBaseEndpoint(), signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [EAgreementServicesTag.Check],
    }),
    getUserAgreements: builder.query<UserAgreement[], {}>({
      keepUnusedDataFor: 0,
      queryFn: async (_, { signal }) => {
        try {
          const response = await UsersApi.getCurrentUserAgreements(getBaseEndpoint(), signal);
          const agreements = response.data;
          const isAllSigned = agreements.every(a => a.agreementDate);
          if (isAllSigned) {
            return { data: agreements };
          }

          return { data: agreements.filter(a => !a.agreementDate) };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    signUserAgreements: builder.mutation<void, SignRequest>({
      queryFn: async (data, { signal }) => {
        try {
          const response = await UsersApi.userAgree(getBaseEndpoint(), data, signal);
          return { data: response.data };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      invalidatesTags: [EAgreementServicesTag.Check],
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          dispatch(agreementsApi.util.upsertQueryData('getUserAgreements', {}, []));
        } catch {
          console.error('Pessimistic update of agreements is failed');
        }
      },
    }),
  }),
});

export const {
  useCheckUserCurrentFetchQuery,
  useSignUserAgreementsMutation,
  useGetUserAgreementsQuery,
  useLazyGetUserAgreementsQuery,
} = agreementsApi;
