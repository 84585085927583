import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import { EGlobalUrlParam } from 'presentation/features/header/utils';
import CorpOfferListContainer from 'presentation/features/offer/corp/list/container';
import { CorpOffersListLocationState } from 'presentation/features/offer/corp/routes';
import { ECorpOfferUrlParam } from 'presentation/features/offer/corp/utils';
import ScreenLayout from 'presentation/layouts/screen';
import { useLocation } from 'react-router';
import { ETradeOfferUrlParam } from '../../features/offer/trade/utils';

const CorpOffersScreen = () => {
  const location = useLocation<Nullable<CorpOffersListLocationState>>();

  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get(EGlobalUrlParam.Search);
  const categoryId = searchParams.getAll(ECorpOfferUrlParam.Category) ?? null;
  const partnerId = searchParams.get(ETradeOfferUrlParam.PartnerId) ?? null;

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <CorpOfferListContainer
            name={name}
            categoryIds={categoryId}
            partnerId={partnerId}
            guid={guid}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default CorpOffersScreen;
