import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Divider, Typography, useMediaQuery } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { ReactNode } from 'react';
import Splitter from '../../../../components/common/splitter';

import { ContentWrapper } from './controls';

type AboutCollapseWrapperProps = {
  readonly id?: string;
  readonly title: ReactNode;
  readonly titleRef: any;
  readonly expanded: boolean;
  readonly isCollapsable: boolean;
  readonly children: any;
  readonly onToggle: () => void;
};

const AboutCollapseWrapper = ({
  id,
  titleRef,
  children,
  expanded,
  isCollapsable,
  title,
  onToggle,
}: AboutCollapseWrapperProps) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  return isCollapsable ? (
    <>
      {isMobile && <Divider />}
      <MPGrid
        id={id}
        container
        justifyContent='space-between'
        onClick={onToggle}
      >
        <Splitter size={4} />
        <MPGrid size={{ xs: 10 }}>
          <Typography
            ref={titleRef}
            color={'textPrimary'}
            variant={'h2'}
          >
            {title}
          </Typography>
        </MPGrid>
        <MPGrid size={{ xs: 1 }}>{expanded ? <ExpandLess /> : <ExpandMore />}</MPGrid>
        <Splitter size={expanded ? 2 : 4} />
      </MPGrid>
      <Collapse
        in={expanded}
        timeout={0}
        unmountOnExit
      >
        {children}
        <Splitter size={isMobile ? 4 : 0} />
      </Collapse>
    </>
  ) : (
    <ContentWrapper>
      <Typography
        id={id}
        ref={titleRef}
        variant={'h2'}
      >
        {title}
      </Typography>
      <Splitter size={isMobile ? 0 : 2} />
      {children}
    </ContentWrapper>
  );
};

export default AboutCollapseWrapper;
