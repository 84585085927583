import { Typography } from '@mui/material';
import CountInput from 'presentation/components/common/countInput';
import Splitter from 'presentation/components/common/splitter';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { Dispatch, FC, SetStateAction } from 'react';

type BookingOfferModalNumberProps = {
  readonly count: number;
  readonly onChange: Dispatch<SetStateAction<number>>;
};

const BookingOfferModalNumber: FC<BookingOfferModalNumberProps> = ({ count, onChange }) => {
  return (
    <>
      <Splitter size={1} />
      <MPGrid>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Количество
        </Typography>
      </MPGrid>
      <Splitter size={1.5} />
      <CountInput
        isInputDisabled
        fullWidth
        min={0}
        size='small'
        value={count}
        onIncrement={onChange}
        onDecrement={onChange}
      />
      <Splitter size={2} />
    </>
  );
};

export default BookingOfferModalNumber;
