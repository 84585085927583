import { Typography } from '@mui/material';
import { toCspRubCurrency } from 'presentationUtils/currency';
import { MPGrid } from 'theme/ui-kit/grid';
import { Wrapper } from './controls';

type CompensationRestProps = {
  readonly limitToSelfRest: Nullable<number>;
  readonly limitToFamilyRest: Nullable<number>;
};

const CompensationRest = ({ limitToSelfRest, limitToFamilyRest }: CompensationRestProps) => {
  return (
    <Wrapper>
      <MPGrid
        container
        spacing={2}
      >
        {!!limitToSelfRest && (
          <MPGrid size={6}>
            <Typography variant='body2'>Для вас</Typography>
            <Typography variant='h2'>{toCspRubCurrency(limitToSelfRest)}</Typography>
          </MPGrid>
        )}
        {!!limitToFamilyRest && (
          <MPGrid size={6}>
            <Typography variant='body2'>Для членов семьи</Typography>
            <Typography variant='h2'>{toCspRubCurrency(limitToFamilyRest)}</Typography>
          </MPGrid>
        )}
      </MPGrid>
    </Wrapper>
  );
};

export default CompensationRest;
