export const isHostOriginSameWeAre = (url: Nullable<string>) => {
  if (!url) {
    return false;
  }

  try {
    const currentHost = window.location.host;
    const inputHost = new URL(url).host;

    return currentHost === inputHost;
  } catch (e) {
    return false;
  }
};

export const getUrlPathname = (url: string) => {
  return new URL(url).pathname;
};

export const getOriginUrlData = (url: Nullable<string>) => {
  return {
    pathname: url ? getUrlPathname(url) : null,
    isSameHost: isHostOriginSameWeAre(url),
  };
};
