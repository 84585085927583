import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import { EGlobalUrlParam } from 'presentation/features/header/utils';
import TradeOfferListContainer from 'presentation/features/offer/trade/list/container';
import { TradeOffersListLocationState } from 'presentation/features/offer/trade/routes';
import { ETradeOfferUrlParam } from 'presentation/features/offer/trade/utils';
import ScreenLayout from 'presentation/layouts/screen';
import { useLocation } from 'react-router';

const TradeOffersScreen = () => {
  const location = useLocation<Nullable<TradeOffersListLocationState>>();

  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get(EGlobalUrlParam.Search);
  const categoryId = searchParams.getAll(ETradeOfferUrlParam.Category) ?? null;

  const partnerId = searchParams.get(ETradeOfferUrlParam.PartnerId) ?? null;

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <TradeOfferListContainer
            name={name}
            categoryIds={categoryId}
            partnerId={partnerId}
            guid={guid}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default TradeOffersScreen;
