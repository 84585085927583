import { useMediaQuery } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import AboutCollapseWrapper from '../components/collapse';
import { AboutInfoSection } from '../types';

type AboutTextSectionsComponentProps<T extends string> = {
  readonly sections: AboutInfoSection<T>[];
  readonly expanded: Nullable<AboutInfoSection<T>>;
  readonly isCollapsable: boolean;
  readonly onToggle: (section: AboutInfoSection<T>) => void;
  readonly setSectionRef: (element: Nullable<HTMLDivElement>, id: T) => void;
};

const AboutTextSectionsComponent = <T extends string>({
  sections,
  expanded,
  isCollapsable,
  onToggle,
  setSectionRef,
}: AboutTextSectionsComponentProps<T>) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <>
      {sections.map(section => {
        const Component = section.component;
        return (
          <div
            key={section.id}
            ref={element => {
              setSectionRef(element, section.id);
            }}
          >
            <Splitter size={isMobile ? 0 : 4} />
            <MPGrid>
              <AboutCollapseWrapper
                id={section.id}
                title={section.name}
                titleRef={null}
                expanded={expanded?.id === section.id}
                isCollapsable={isCollapsable}
                onToggle={() => onToggle(section)}
              >
                <Component />
              </AboutCollapseWrapper>
            </MPGrid>
          </div>
        );
      })}
    </>
  );
};

export default AboutTextSectionsComponent;
