import { Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPFormTextArea } from 'presentation/theme/ui-kit/input';
import React from 'react';
import { SidebarProps } from '../../details/types';
import { ESlotNameArea, SlotsGridArea } from '../claim/controls';
import BookingSidebarSlot from './slot';
import { MPLink } from 'theme/ui-kit/link';

export type BookingSidebarSlotsProps = SidebarProps & {
  readonly totalPrice: Nullable<string>;
  readonly onSelectItem: (index: number[]) => void;
  readonly onDeleteItem: (index: number[]) => void;
};

const BlankButton = (
  <MPButton
    key='disabled'
    fullWidth
    disabled
  >
    Выберите минимум одну услугу
  </MPButton>
);

const useBookingSidebarSlots = ({
  isLoading,
  cart,
  totalPrice,
  customerComment,
  onSelectItem,
  onDeleteItem,
  onOrder,
  onChangeCustomerComment,
  onShowPersonalDataTransferInfo,
}: BookingSidebarSlotsProps): Nullable<React.JSX.Element>[] => {
  let slots: Nullable<React.JSX.Element>[] =
    cart?.map((item, cartIndex) => (
      <BookingSidebarSlot
        key={item.orderItem.priceItem.id}
        divider={cartIndex !== cart.length - 1}
        slotIndex={cartIndex}
        onChange={onSelectItem}
        onDelete={onDeleteItem}
        {...item}
      />
    )) ?? [];

  if (!cart || cart.length === 0) {
    slots = [BlankButton];
  }

  if (cart?.length) {
    slots = slots.concat(
      <SlotsGridArea
        area={ESlotNameArea.bottomBar}
        key={ESlotNameArea.bottomBar}
      >
        <Splitter size={1} />
        <MPFormTextArea
          multiline
          fullWidth
          rows={3}
          slotProps={{ htmlInput: { maxLength: 1000 } }}
          label='Напишите ваши пожелания'
          size='small'
          value={customerComment}
          onChange={event => onChangeCustomerComment(event.target.value)}
        />
        <Splitter size={3} />
        <MPGrid
          container
          spacing={1}
          justifyContent='space-between'
          alignItems='center'
          flexWrap='nowrap'
        >
          <MPGrid>
            <Typography variant='subtitle2'>Предварительная сумма:</Typography>
          </MPGrid>
          <MPGrid>
            <Typography
              variant='h2'
              noWrap
            >
              {totalPrice}
            </Typography>
          </MPGrid>
        </MPGrid>
        <Splitter size={1.5} />
        <Typography
          variant='body2'
          color='textSecondary'
        >
          После оформления заказа вам перезвонит менеджер для уточнения деталей заказа и&nbsp;итоговой стоимости.
        </Typography>
        <Splitter size={3} />
        <Typography variant='body2'>
          Нажимая кнопку «Оформить заказ», я&nbsp;даю согласие ОАО&nbsp;«РЖД» на&nbsp;передачу моих{' '}
          <MPLink
            component='span'
            onClick={onShowPersonalDataTransferInfo}
          >
            персональных данных
          </MPLink>{' '}
          партнёру Витрины.
        </Typography>
        <Splitter size={2} />
        <MPButton
          fullWidth
          disabled={isLoading || !onOrder}
          color='brand'
          size='large'
          onClick={onOrder || undefined}
        >
          Оформить заказ
        </MPButton>
      </SlotsGridArea>
    );
  }

  return slots;
};

export default useBookingSidebarSlots;
