import { Typography } from '@mui/material';
import { LogoImageView } from 'components/common/images/cover';
import { PartnerShort, PartnerView } from 'domain/model';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { LogoImageViewWrapper, PartnerWrapper } from './controls';

type OfferPartnerInfoProps<T extends PartnerView | PartnerShort> = {
  readonly partner: T;
  readonly onClick: () => void;
};

const OfferPartnerInfo = <T extends PartnerView | PartnerShort = PartnerView>(props: OfferPartnerInfoProps<T>) => {
  const { partner, onClick } = props;

  const { logo } = partner;

  return (
    <PartnerWrapper onClick={onClick}>
      <MPGrid
        container
        spacing={1}
        alignItems='center'
      >
        {logo?.path && (
          <MPGrid>
            <LogoImageViewWrapper>
              <LogoImageView id={logo.path} />
            </LogoImageViewWrapper>
          </MPGrid>
        )}
        <MPGrid
          container
          flexDirection='column'
          size='grow'
          spacing={0.25}
        >
          <MPGrid size='auto'>
            <Typography>{partner.name}</Typography>
          </MPGrid>
          <MPGrid size='auto'>
            <Typography
              variant='caption'
              color='secondary'
            >
              Перейти на страницу партнера
            </Typography>
          </MPGrid>
        </MPGrid>
      </MPGrid>
    </PartnerWrapper>
  );
};

export default OfferPartnerInfo;
