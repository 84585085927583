import { Fade, Typography, useMediaQuery } from '@mui/material';
import { Category } from 'domain/model';
import Splitter from 'presentation/components/common/splitter';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPButtonsGroup } from 'presentation/theme/ui-kit/button/group';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { pluralize } from 'presentation/utils';
import { useSelector } from 'react-redux';
import { productOfferListIsNewSearchFetchingSelector } from '../../store/selectors';
import { SelectWrapper } from './controls';

type ProductOffersSearchHeaderProps = {
  readonly category: Nullable<Category>;
  readonly count: number;
  readonly name: Nullable<string>;
  readonly categories: Category[];
  readonly sortSelectComponent: React.ReactNode;
  readonly filterComponent: React.ReactNode;
  readonly onChangeCategory: (category: Category) => void;
};

const ProductOffersSearchHeader = (props: ProductOffersSearchHeaderProps) => {
  const { category, count, name, categories, sortSelectComponent, filterComponent, onChangeCategory } = props;

  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { x1 } = useGridSpacing();

  const isNewSearchFetching = useSelector(productOfferListIsNewSearchFetchingSelector);

  const CategoryName = () => {
    if (categories.length === 0) {
      return null;
    }

    return (
      <MPGrid
        container
        spacing={x1}
        alignItems='center'
      >
        <MPGrid size={{ zero: 12, sm: 6 }}>
          <Typography
            variant='h3'
            component='h2'
          >
            {categories[0].name}
          </Typography>
        </MPGrid>
        <MPGrid size={{ zero: 12, sm: 6 }}>
          {isSmDown && <Splitter size={1.5} />}
          <MPGrid
            container
            flexWrap='nowrap'
            alignItems='center'
            justifyContent={isSmDown ? 'space-between' : 'flex-end'}
            spacing={2}
          >
            <MPGrid>{sortSelectComponent}</MPGrid>
            {isMdDown && <MPGrid>{filterComponent}</MPGrid>}
          </MPGrid>
        </MPGrid>
      </MPGrid>
    );
  };

  const CategoryList = () => {
    if (isNewSearchFetching) {
      return null;
    }

    return (
      <MPGrid
        container
        spacing={x1}
        justifyContent='space-between'
        alignItems='center'
      >
        <MPGrid>
          <Typography
            variant='h3'
            component='h2'
          >
            {count === 1 ? 'Найден ' : 'Найдено '} {count}{' '}
            {pluralize(count, ['результат', 'результата', 'результатов'])}
            {categories.length > 0 &&
              ` в ${categories.length} ${pluralize(categories.length, ['категории', 'категориях', 'категориях'])}`}
          </Typography>
          <Splitter size={1} />
        </MPGrid>
        <SelectWrapper>
          {isSmDown && <Splitter size={1} />}
          {sortSelectComponent}
        </SelectWrapper>
        <MPGrid size={12}>
          <MPButtonsGroup<Category>
            color='secondary'
            exclusive
            items={categories}
            selected={category ? [category] : categories}
            onChange={([value]) => onChangeCategory(value)}
          />
        </MPGrid>
      </MPGrid>
    );
  };

  return (
    <Fade in>
      <div>{!name ? <CategoryName /> : <CategoryList />}</div>
    </Fade>
  );
};

export default ProductOffersSearchHeader;
