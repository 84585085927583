import { PartitionIcon } from 'presentation/components/common/partitionIcon';
import { EAppCategories } from 'presentation/types';
import OffersCategoryHeader from '../../../components/categoryHeader';
import OffersCommonHeader from '../../../components/commonHeader';
import OffersSearchHeader from '../../../components/searchHeader';
import { useContextHandlers } from '../hooks/useContextHandlers';
import useAspOffersHeader from '../hooks/useHeader';

export const AspOffersHeaderAdapter = () => {
  const handlers = useContextHandlers();

  const {
    isNewSearchFetching,
    isSearchHeaderVisible,
    isCommonHeaderVisible,
    isCategoryHeaderVisible,
    isAnyHeaderVisible,
    selectedCategory,
    categoriesOut,
    initialTotalCount,
  } = useAspOffersHeader();

  if (!isAnyHeaderVisible || isNewSearchFetching) {
    return null;
  }

  return (
    <div>
      {isSearchHeaderVisible && (
        <OffersSearchHeader
          category={selectedCategory}
          count={initialTotalCount}
          categories={categoriesOut}
          onChangeCategory={handlers.onChangeCategory}
        />
      )}
      {isCategoryHeaderVisible && selectedCategory && (
        <OffersCategoryHeader
          category={selectedCategory}
          onChangeCategory={handlers.onReturnToTopCategory}
        />
      )}
      {isCommonHeaderVisible && (
        <OffersCommonHeader
          icon={
            <PartitionIcon
              fontSize='large'
              type={EAppCategories.AspOffers}
            />
          }
        >
          {EAppCategories.AspOffers}
        </OffersCommonHeader>
      )}
    </div>
  );
};
